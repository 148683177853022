import detailDefault from "../../detailDefault";

export default class detailUsers extends detailDefault {
    load(sAction, data) {
        if(sAction.userPreferences.is_admin == 1){
            sAction.dataSet(data.prefix+"/fields/calendar_users/def/readonly", false);
        }
    }
    update(sAction, data) { }
}
