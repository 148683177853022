/**
 *
 * @param type
 * @param data
 */
export default function custom(type, data) {
  data.view = this.getViewName();

  let module = this.getModuleName();
  if (data.module != undefined && data.module != null) {
      module = data.module;
  }
  const detailCustom = this.customClassPole['detail' + module];
  if (detailCustom != undefined) {
      this.customClass = new detailCustom();
  } else {
      this.customClass = null;
  }
  this.dataSet(data.prefix + '/doCustom', false);
  if (this.customClass != null) {
      const allowedHooks = [
          'load',
          'update',
          'beforeSave',
          'newSubRecord',
          'subpanel',
          'updateDetailLine',
          'checkDuplicity',
      ];

      if (!allowedHooks.includes(type)) {
          console.error('nespecifikovaný hook ' + type);
          return false;
      } else {
          return this.customClass[type]?.(this, data);
      }
  }
  return true;
}
