export default function vzChangeState(params){
    var reqFields = [];

    let fieldsValid = true;

    if(params.newState === "notsent" ||
    params.newState === "cancelclient"
    ){
        reqFields.push("resultcomment");
    }
    else if(params.newState === "preparequote"){
        reqFields.push("kdopodava");
        reqFields.push("jistina_select");
        reqFields.push("nahradniplneni_select");
        let jistinaVal = this.dataGet(params.prefix + "/fields/jistina_select/value");
        if(jistinaVal === "yes"){
            reqFields.push("jistinaamount");
        }

    }
    else if(params.newState === "sent"){
        reqFields.push("nabidkovacena");
        reqFields.push("datumpodani");
    }
    else if(params.newState === "win" || params.newState === "contract"){
        reqFields.push("resultcomment");
        reqFields.push("vyhernicena");
    }
    else if(params.newState === "lost"){
        reqFields.push("resultcomment");
        
    }

    reqFields.forEach(field => {
        let fVal = this.dataGet(params.prefix + "/fields/"+field+"/value");
        if(!fVal){
            fieldsValid = false;
            this.dataSet(params.prefix+"/fields/"+field+"/customClass", "error");
        }
    });

    if(!fieldsValid){
        this.error(this.translate("LBL_ERR_FILL_RAQUIRED_FIELDS", "Opportunities"));
        return;    
    }

    params.action = "vz_change_state"

    if(params.newState == "contract"){
        //je treba vytvorit smlouvu

        this.load();
        this.rest.post("customAction", {action: "vz_check_contracts", record: params.id}, (respCon) => {
            if(respCon.status){
                params.contract_id = respCon.message.contract_id;
            }

        this.unLoad();

        if(params.contract_id){
            vzChangeStateHelper(this, params);
        }
        else{
            this.detailPredefinedFields = [];
            this.detailPredefinedFields.push({name: "name", value: params.vzName, type: "field"});

            let parentId = this.dataGet(params.prefix+"/fields/accounts_acm_verejne_zakazky_1_name/def/id_value");
            let parentName = this.dataGet(params.prefix+"/fields/accounts_acm_verejne_zakazky_1_name/value");


            this.detailPredefinedFields.push({
                fieldName: "acm_contracts_accounts_name",
                name: "acm_contracts_accountsaccounts_ida",
                value: {name : parentName, id : parentId},
                type: "relate_simple"
            });

            this.detailPredefinedFields.push({
                fieldName: "acm_verejne_zakazky_acm_contracts_1_name",
                name: "acm_verejne_zakazky_acm_contracts_1acm_verejne_zakazky_ida",
                value: {name : params.vzName, id : params.id},
                type: "relate_simple"
            });

            this.popupDetail({
                module: "acm_contracts",
                record: "",
                saveCallback: conData => {
                    if(conData.record){
                        params.contract_id = conData.record;
                        vzChangeStateHelper(this, params);
                    }
                },
                exitCallback: data => {

                }
            });
        }
        })

    }
    else{
        vzChangeStateHelper(this, params);
    }




}

function vzChangeStateHelper(sAction, params){
    sAction.load();

    sAction.rest.post("customAction", params, (resp) => {
        sAction.unLoad();
        if(resp.errCode == "OK"){
            location.reload();
            //this.href("#detail/Opportunities/"+resp.record);
        }
        else{
            sAction.error(sAction.translate(resp.errMsg, "ACM_verejne_zakazky"));
        }
    });
}