import React from "react";
import PureComponent from "../../pure";

import  sAction  from "sAction";
import Button from "../../formElements/Button";
import Relate from "../../formElements/Relate";
import Select from "../../formElements/Select";
import InputText from "../../formElements/InputText";
import RadioGroup from "../../formElements/RadioGroup";
import Parent from "../../formElements/Parent";
import AcmDateTime from "../../formElements/AcmDatetime";

export default class ListviewDocumentMassAssignCategory extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            categoryName: '',
            categoryID: '',
            error: false,
        };

    }

    setRelate(item) {
        if (item.id) {
            this.setState({categoryName: item.name, categoryID: item.id, error: false});
        } else {
            this.setState({categoryName: item.name, categoryID: null});
        }
    }

    setCategory() {
        if (this.state.categoryID) {
            sAction.load();
            let data = this.props.data;
            sAction.rest.post('setMassDocumentCategory', {data, catID: this.state.categoryID}, returnData => {
                sAction.popupHide();
                sAction.unLoad();
                if (returnData.status) {
                    const data = {
                        // Potvrzení
                        header: sAction.translate('LBL_CONFIRM', 'Documents'),
                        buttons: [
                            // Pokračovat
                            {label: sAction.translate('LBL_CONTINUE', 'Documents'), callback: () => location.reload()},
                        ],
                    };

                    sAction.popup(sAction.translate(returnData.message.text, 'Documents'), data);
                } else {
                    sAction.error(sAction.translate(returnData.errorMessage.text, 'Documents'));
                }
            });
        } else {
            this.setState({error: true});
        }
    }

    render() {
        console.log('AAA', this.state)

        let userName = this.state.categoryName;
        let userId =  this.state.categoryID;

        return (
            <div>
                <div className="acmPopupHeader">{sAction.translate('LBL_MASS_ASSIGN_CATEGORY', 'Documents')}</div>
                <div className="acmPopupContent">
                    <div className="multipleCallsTable">
                        <div className="mcRow">
                            <div className="mcCell">{sAction.translate('LBL_ASSIGN_DOC_CATEGORY', 'Documents')}</div>
                            <div className="mcCell">
                                <Relate
                                    newRecord={false}
                                    module='acm_documentsCategory'
                                    data={{
                                        value: userName,
                                        id_value: userId
                                    }}
                                    buttons={[]}
                                    callback={(item) => this.setRelate(item)}
                                    key={userId}
                                    error={this.state.error}
                                />
                            </div>
                        </div>
                        <div className="mcRow">
                            <div className="mcCell mcButtons viewActionPanelButtons">
                                <Button onClick={() => sAction.popupHide()}>
                                    {sAction.translate('LBL_CANCEL', 'Documents')}
                                </Button>
                                <Button onClick={() => this.setCategory()}>
                                    {sAction.translate('LBL_ASSIGN_CATEGORY','Documents')}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
