export default function calSaveActivityDetailHelper(params, callback){
  const prefix = params.prefix;
  const prefixInv = params.prefixInv;
  const canSave = this.dataGet(prefix+"/canSave");

  var id = this.dataGet(prefix+"/id");

  const module = this.dataGet(prefix+"/module");

  var fields = this.dataGet(prefix+"/fields");

  const editFields = this.dataGet(prefix+"/changes/fields");
  const invitees = this.dataGet(prefixInv+"/relatedInv").toJS();
  var self = this;
  self.dsClear();

  let ignoredFields = ["id"];
  const visibleFields = [];
  const tabs = this.dataGet(prefix + "/tabs");
  tabs.forEach((tab, index) => {
      tab.rows.forEach((row, index) => {
        row.forEach((line, index) => {
          if (tab.hidden) {
          ignoredFields.push(line);
          }else{
            visibleFields.push(line)
          }
        });
      });
  });

  const hiddenFields = this.dataGet(prefix+"/hiddenFields");
  hiddenFields.forEach(field => {
    ignoredFields.push(field);
  })

  if (canSave == true) {
      if(!this.custom("beforeSave",{})){
      }
      
      var sendToCrm = true;
      fields.valueSeq().forEach(data => {
        if (ignoredFields.includes(data.name)) {
          return;
        }
        if(!visibleFields.includes(data.name)){
          return;
        }
        if(data.def){
          if (data.def.get("required") == true && (data.value === "" || data.value === null)) {
            self.dsAdd("set", prefix+"/fields/"+data.name+"/def/isInvalid", true);
            self.dsAdd("set", prefix+"/fields/"+data.name+"/customClass", "error");
            sendToCrm = false;
          }
          else{
            self.dsAdd("set", prefix+"/fields/"+data.name+"/def/isInvalid", false);
            self.dsAdd("set", prefix+"/fields/"+data.name+"/customClass", "");
          }
        }
      });

      self.dsProcess();

      if (sendToCrm == false) {
          
      } else {
          self.load();

          if(id == undefined){
            id = "";
          }

          var pomEditFields = {fields: editFields.toJS(), invitees: invitees};
          this.rest.post("set/" + module + "/" + id, pomEditFields, function(msg) {
            const data = {
               state: true,
               data: msg
            };

            self.popupHide(self.clearCalDetailStore.bind(null, params.pomWay));
            // TTT PRERENDER
            if(prefix.indexOf("calendar") !== -1){
              self.calGetEvents(true);
            }
            else{
              const pomWay = prefix.substr(0, prefix.indexOf('/data/viewData/'));
              const attrs = {
                action : "refresh",
                cssSelector : "#dashFC_"+self.dataGet(pomWay+"/data/viewData/dashletId"),
                way: pomWay
              };
              self.calActivityDashlet(attrs);
            }
            
            if(callback != undefined){
              callback(data);
            }
          });

          this.removeChangesFromLS(module,this.dataGet('conf/user/id'))
          self.unLoad();

      }
    }

}
