export default function oppChangeState(params){

    var reqFields = [];

    if(params.newState === "Value Proposition"){
        reqFields.push("amount");
    }

    switch(params.newState){
        case "Closed Lost":
            reqFields.push("konkurence_c");
        case "Suspended":
        case "Closed canceled client":
        case "Closed canceled me":
            reqFields.push("close_reason");
        
        case "Qualification":
        case "Value Proposition":
        case "Negotiation/Review":
        case "Closed Won":
            
            if(params.newState !== "Qualification"){
                reqFields.push("contacts_opportunities_1_name");
            }    

            let fieldsValid = true;
            reqFields.forEach(field => {
                let fVal = this.dataGet(params.prefix + "/fields/"+field+"/value");
                if(!fVal){
                    fieldsValid = false;
                    this.dataSet(params.prefix+"/fields/"+field+"/customClass", "error");
                }
            });

            if(!fieldsValid){
                this.error(this.translate("LBL_ERR_FILL_RAQUIRED_FIELDS", "Opportunities"));
                return;    
            }

            this.load();
            params.action = "opp_change_state"
            this.rest.post("customAction", params, (resp) => {
                this.unLoad();
                if(resp.errCode == "OK"){
                    location.reload();
                    //this.href("#detail/Opportunities/"+resp.record);
                }
                else{
                    this.error(this.translate(resp.errMsg, "Opportunities"));
                }
            });
            break;
    }
}