import detailDefault from "../../detailDefault";
export default class detailACM_verejne_zakazky extends detailDefault {
  load(sAction, data) {
    
    //admini a nektere role muzou editovat stav VZ

    let roles = sAction.dataGet(data.prefix+"/customData/user_roles");

    if (
        sAction.dataGet("conf/user/is_admin") == 1 || 
        roles.includes('df43ac6b-4d78-b854-cc9e-609a21ff521a') ||
        roles.includes('cf1274e0-85a3-b132-59d4-609a2046b3fc')
    ) {
        sAction.dsClear();

        sAction.dsAdd('set', data.prefix+"/fields/vzstage/def/readonly", false);

        sAction.dsProcess();
    }
    
  }

  
}
