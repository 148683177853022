import React from "react";

import GenerateChart from "../charts/GenerateChart";
import ListViewTable2 from "../list/listViewTable2";
import Report from "../CustomReport/Report";
import CalendarDashlet from "./widgets/CalendarDashlet";

import  sAction  from "sAction";

/**
 * @parent  src\components\home\HomeColumns.js
 * @props   data, def, way
 *
 * zobrazi obsah budiku podle jeho typu (graf, text...)
 */
export default class HomeWidgetContent extends React.Component {
  render() {
    const { data, def, way } = this.props;

    var customClass = "";
    let render = "";
    const type = def.get("type");

    switch (type) {
      case "chart":
        render = <GenerateChart data={data} />;
        break;

      case "text":
        render = data.get("text");
        break;

      case "view":

        const viewData = data.get("viewData");
        if (!viewData) {
          render = sAction.translate("LBL_LOADING");
        } else {
          render = (
            <ListViewTable2
              data={viewData}
              prefix={way + "/data/viewData"}
              parent={this}
            />
          );
        }
        customClass = "widgetListView";
        break;
      case "customReport":
      render = <Report data={data.get("viewData")} reportId={data.get("viewId")} widgetId={data.get("widgetId")} way={way+"/data/viewData"} widget={1} />
      customClass = "widgetCustomReport";
      break;
      case "calendar":
        const viewDataCal = data.get("viewData");
        if (!viewDataCal){
          render = sAction.translate("LBL_LOADING");
        }
        else{
        render = <CalendarDashlet
          data={viewDataCal}
          reportId={viewDataCal.dashletId}
          way={way} />
        }
        break;

      case "iframe":
        return <iframe className="iframe" src={data.get("url")} />;
        break;

      default:
        render = <p>Neznámý typ budíku: {type}</p>;
    }

    let content = !this.props.chartId ? (
      render
    ) : (
      <div className={this.props.chartId}>{render}</div>
    );

    return <div className={"homeCardContent "+customClass}>{content}</div>;
  }
}
