export default function openDefaulListViewEdit(way) {
  const data = this.dataGet(way);
  const module = data.modul;
  this.load();

  const params = {
    module: module,
    listview_type: data.type || "list"
  };
  var parent = "";
  if (params.listview_type == "subpanel") {
    parent = this.dataGet(way + "/../../../module");
    params["parent"] = parent;
  }

  this.rest.post("openDefaultListView", params, (defsData) => {
    this.dsClear();
    const id = this.dataGet("conf/popup/show") ? "2" : "";
    this.dsAdd("set", "conf/popup" + id + "/show", true);
    this.dsAdd("set", "conf/popup" + id + "/content", "defaultListViewEdit");
    this.dsAdd("set", "conf/popup" + id + "/data/columns", {}, "reportWindowColumns");

    var fields = {
      fields: [],
      relate: []
    };
    const active = {};
    const hidden = {};
    defsData.fields.forEachObject((field, key) => {
      if (field != null) {
        if (field.type == "relate" || field.type == "link") {
          if (field.searchable == true) {
            fields.relate.push(field);
          }
        } else {
          fields.fields.push(field);
        }
      }

      const k = key.toLowerCase();

      // let def = defsData[k];
      if (field && field.type !== "link") {
        field["show"] = true;
        field["fieldModule"] = module;
        if (field["default"] && defsData.active.length == 0) {
          /*  this.dsAdd(
                'add',
                'conf/popup/data/columns/active',
                { name: k, def: field },
                'reportWindowColumn'
            ); */
          active[k] = field;
        } else {
          /*  this.dsAdd(
                'add',
                'conf/popup/data/columns/hidden',
                { name: k, def: field },
                'reportWindowColumn'
            ); */
          hidden[k] = field;
        }
      }
    });
    if (defsData.active.length != 0) {
      defsData.active.forEach((field) => {
        if (hidden[field.field] != undefined) {
          const fieldDef = hidden[field.field];
          fieldDef["width"] = field.width;
          active[field.field] = fieldDef;
          delete hidden[field.field];
        }
      });
    }

    hidden.forEachObject((def, name) => {
      this.dsAdd(
        "add",
        "conf/popup" + id + "/data/columns/hidden",
        { name: name, def: def },
        "reportWindowColumn"
      );
    });
    active.forEachObject((def, name) => {

      this.dsAdd(
        "add",
        "conf/popup" + id + "/data/columns/active",
        { name: name, def: def },
        "reportWindowColumn"
      );
    });

    //    this.dsAdd("set", "reportWindow/metadata", {}, "reportWindowMetadata");
    this.dsAdd("set", "conf/popup" + id + "/data/header", this.translate("LBL_LISTVIEW_EDIT"));
    this.dsAdd("set", "conf/popup" + id + "/data/module", module);
    this.dsAdd("set", "conf/popup" + id + "/data/fields", fields.fields);
    this.dsAdd("set", "conf/popup" + id + "/data/relate", fields.relate);
    this.dsAdd("set", "conf/popup" + id + "/data/listview_type", params["listview_type"]);
    if (data.type == "subpanel") {
      this.dsAdd("set", "conf/popup" + id + "/data/parent", parent);
    }
    this.dsAdd("set", "conf/popup" + id + "/data/activeRelate", [
      { main: true, module: module }
    ]);
    this.dsProcess();
    this.unLoad();
  });
}
