export default function salesPlanImport(data){
    var input = document.createElement('input');
    input.type = "file";
    input.style = "display:none";
    // input.multiple = true;
    input.onchange = (e) => {
      const sendData = {
        data: {
          module: data.module,
          id: data.id,
          files: e.target.files,
        },
        action: "importUpdateFile",
        responseType: "json",
        success: response => {
          this.unLoad();

          if(response.status == "ok"){

            const data = {
              // Potvrzení
              header: this.translate("LBL_CONFIRM"),
              // color:"red",
              buttons: [
                // Zrušit
                {label: this.translate("LBL_CANCEL", "Home"), callback: () => this.popupHide()},
    
                // Pokračovat
                {label: this.translate("LBL_CONTINUE", "Home"), callback: () => location.reload()},
              ],
            };
    
            //this.alert(response.message + "<br><br>Konec zpracování. Bylo využito " + response.imported_count + " řádků z celkového počtu " + response.all_count + " řádků. Stránka bude znovu načtena pro projevení změn...", data);

            this.alert(this.translate("LBL_IMPORTED_ROWS_CNT", "acm_salesplan")+ ": "+response.imported_count);
          } 
          else{
            this.error(response.message);
          }

          
  
          // this.unLoad();
          // console.log(response);
        },
        progress: progress => {
        }
      }
      this.load();
      this.filesXhr(sendData);
    }
  
    setTimeout(function () {
      input.click();
    }, 200);
  
}