import detailDefault from "../../detailDefault";
export default class detailacm_salesplan extends detailDefault{
    load(sAction,data){
        //$("#whole_year").closest(".detailViwRow").css("visibility", "hidden");
        
        this.checkIndefinitely(sAction, data);

    }
    update(sAction,data){
        if(data.field == "indefinitely"){
            this.checkIndefinitely(sAction, data);
        }
    }

    checkIndefinitely(sAction, data){
        let indefinitely = sAction.dataGet(data.prefix+"/fields/indefinitely/value");
        console.log("indefinitely", indefinitely);

        if(indefinitely == "1"){
            sAction.dataSet(data.prefix+"/fields/valid_to/def/required", false);
        }
        else{
            sAction.dataSet(data.prefix+"/fields/valid_to/def/required", true);
        }

    }
}