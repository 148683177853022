import detailDefault from "../../detailDefault";

export default class detailacm_calculation_quote extends detailDefault {
    // Pole které se zobrazí když je typ služby Nájem + klik
    rentAndClickFields = [
        'cost_price_print_black_white',
        'cost_price_print_color',
        'sell_price_print_black_white',
        'sell_price_print_color',
    ];

    // Pole které se zobrazí když je typ služby Nájem + toner
    rentAndTonerFields = [
        'cost_price_toner_black',
        'cost_price_toner_cyan',
        'cost_price_toner_magenta',
        'cost_price_toner_yellow',
        'sell_price_toner_black',
        'sell_price_toner_cyan',
        'sell_price_toner_magenta',
        'sell_price_toner_yellow',
    ];

    load(sAction, data){
        sAction.dsClear();
        const customLines = sAction.dataGet(`${data.prefix}/customData/customLines/lines`);

        // Zobrazení polí podle toho jaký je typ služby
        for (let i = 0; i <= customLines?.size; i++) {

            const serviceType = sAction.dataGet(`${data.prefix}/customData/customLines/lines/${i}/service_type`);
            const category = sAction.dataGet(`${data.prefix}/customData/customLines/lines/${i}/category`);
            const productName = sAction.dataGet(`${data.prefix}/customData/customLines/lines/${i}/acm_calculation_products_acm_calculation_quote_lines_1_name`);

            this.setProductsByCategory(sAction, {value: category, way: data.prefix}, i);

            // Nájem + toner
            if (serviceType === 'rent_and_toner') {
                this.rentAndTonerFields.forEach((field) => {
                    sAction.dsAdd('set', `${data.prefix}/customData/customLines/customStyle/${i}/${field}/display`, 'inherit');
                });
                this.rentAndClickFields.forEach((field) => {
                    sAction.dsAdd('set', `${data.prefix}/customData/customLines/customStyle/${i}/${field}/display`, 'none');
                });
            }

            // Nájem + klik
            if (serviceType === 'rent_and_click') {
                this.rentAndTonerFields.forEach((field) => {
                    sAction.dsAdd('set', `${data.prefix}/customData/customLines/customStyle/${i}/${field}/display`, 'none');
                });
                this.rentAndClickFields.forEach((field) => {
                    sAction.dsAdd('set', `${data.prefix}/customData/customLines/customStyle/${i}/${field}/display`, 'inherit');
                });
            }

            if (category !== 'A3' || productName.toLowerCase()?.includes('epson')) {
                sAction.dsAdd('set', `${data.prefix}/customData/customLines/customStyle/${i}/discount/display`, 'none');
            }
        }

        sAction.dsProcess();
    }

    // Funkce slchová pole slevy a přepočítá pole které sleva ovlivňuje
    hideDiscount(sAction, data) {
        const discount = sAction.dataGet(`${data.prefix}/discount`);
        data.prevValue = discount;
        data.value = 0;
        this.calculateFieldsWithDiscount(sAction, data);
        sAction.dsAdd('set', `${data.way}/customData/customLines/customStyle/${data.lineIndex}/discount/display`, 'none');
        sAction.dsAdd('set', `${data.prefix}/discount`, 0);
    }

    updateDetailLine(sAction, data){

        // Pokud se změní pole kategorie rozhoduje se jestli se má nebo nemá zobrazit pole sleva
        if (data.field === 'category') {
            this.setProductsByCategory(sAction, data, data.lineIndex);
            const serviceType = sAction.dataGet(`${data.prefix}/service_type`);
            const productName = sAction.dataGet(`${data.prefix}/acm_calculation_products_acm_calculation_quote_lines_1_name`);

            sAction.dsClear();
            if (data.value !== 'A3' || serviceType !== 'rent_and_click' || productName.toLowerCase()?.includes('epson')) {
                this.hideDiscount(sAction, data);
            } else {
                if (serviceType === 'rent_and_click') {
                    sAction.dsAdd('set', `${data.way}/customData/customLines/customStyle/${data.lineIndex}/discount/display`, 'inherit');
                }
            }
            sAction.dsProcess();
        }

        // Pokud se změní pole typ služby rozhoduje se jestli se má nebo nemá zobrazit pole sleva
        if (data.field === 'service_type') {
            this.hideFieldsByServiceType(sAction, data);
            const category = sAction.dataGet(`${data.prefix}/category`);
            const productName = sAction.dataGet(`${data.prefix}/acm_calculation_products_acm_calculation_quote_lines_1_name`);

            sAction.dsClear();
            if (category !== 'A3' || data.value !== 'rent_and_click' || productName.toLowerCase()?.includes('epson')) {
                this.hideDiscount(sAction, data);
            } else {
                if (data.value === 'rent_and_click') {
                    sAction.dsAdd('set', `${data.way}/customData/customLines/customStyle/${data.lineIndex}/discount/display`, 'inherit');
                }
            }
            sAction.dsProcess();
        }

        // Když se zvolí produkt musí se předvylnit data z produktu
        if (data.field === 'acm_calcul4965roducts_ida') {
            this.setLineData(sAction, data);
        }

        // Při změně pole slevy přepočítat pole které sleva ovlivňuje
        if (data.field === 'discount') {
            if (data.value > 100 || data.value < 0) {
                data.value = 0;
            }
            if (data.prevValue > 100 || data.prevValue < 0) {
                data.prevValue = 0;
            }
            this.calculateFieldsWithDiscount(sAction, data);
        }

        // Při změné pole množství, počet výtisku čb nebo počet výtisků barva přepočítat cenu za doručení
        if (data.field === 'amount' || data.field === 'print_amount_color' || data.field === 'print_amount_black_white') {
            if (data.prevValue !== data.value) {
                this.calculateShippingCosts(sAction, data);
            }
        }

        // Při změně mnoštví přepočítat pole které množství ovlivňuje
        if (data.field === 'amount') {
            this.calculateFieldsWithNewAmount(sAction, data);
        }

        // Při počet měsiců služby nebo ceny příslušenství přepočítat pole
        // Používá se fce na přepočítání polí když se změní množství protože to jsou stejná pole (skoro všechny)
        if (data.field === 'months_of_service' || data.field === 'price_accessories_and_installation') {
            const amount = sAction.dataGet(`${data.prefix}/amount`);
            this.calculateFieldsWithNewAmount(sAction, {value: amount, prefix: data.prefix});
        }

        const serviceType = sAction.dataGet(`${data.prefix}/service_type`);
        this.calcMarginBlackWhite(data, serviceType, sAction);
        this.calcMarginColor(data, serviceType, sAction);
    }

    // Funkce přepočítá pole které jsou ovlivněny slevou
    calculateFieldsWithDiscount(sAction, data) {
        sAction.dsClear();
        const discount = data.value || 0;
        const printColorPrice = sAction.dataGet(`${data.prefix}/cost_price_print_color`);
        const colorToner = !printColorPrice || printColorPrice !== '0.00' || printColorPrice !== '0';

        const monthsOfService = parseInt(sAction.dataGet(`${data.prefix}/months_of_service`));
        const amount = sAction.dataGet(`${data.prefix}/amount`);

        const warrantyExtension = sAction.dataGet(`${data.prefix}/service_${monthsOfService}`) || 0;
        const warrantyExtensionBase = warrantyExtension * amount;
        const newWarrantyExtension = warrantyExtensionBase - (warrantyExtensionBase * (discount / 100));

        const priceHwTotal = parseFloat(sAction.dataGet(`${data.prefix}/price_hw_total`));
        const interest = parseFloat(sAction.dataGet(`${data.prefix}/interest`));
        const shippingCosts = parseFloat(sAction.dataGet(`${data.prefix}/shipping_costs`));

        const newTotalCostService = newWarrantyExtension + priceHwTotal + interest + shippingCosts;
        sAction.dsAdd('set', `${data.prefix}/warranty_extension`, newWarrantyExtension.toFixed(2));
        sAction.dsAdd('set', `${data.prefix}/total_costs_service`, newTotalCostService.toFixed(2));

        // Color
        const printColorOnePiece = parseFloat(sAction.dataGet(`${data.prefix}/cost_price_print_color_original`));
        const newCostPrintColor = printColorOnePiece + ((warrantyExtensionBase * 0.2 * discount / 100 / monthsOfService) / 500);
        sAction.dsAdd('set', `${data.prefix}/cost_price_print_color`, newCostPrintColor.toFixed(4));

        // Black white
        const printBwOnePiece = parseFloat(sAction.dataGet(`${data.prefix}/cost_price_print_black_white_original`));
        let clickConstant = 0.8;
        if (!colorToner) {
            clickConstant = 1;
        }
        const newCostPrintBw = printBwOnePiece + ((warrantyExtensionBase * clickConstant * discount / 100 / monthsOfService) / 2000);
        sAction.dsAdd('set', `${data.prefix}/cost_price_print_black_white`, newCostPrintBw.toFixed(4));

        // TONERS
        let tonerConstant = 0.25;
        if (!colorToner) {
            tonerConstant = 1;
        }

        // Toner black
        const yieldBlackWhite = parseInt(sAction.dataGet(`${data.prefix}/yield_black_white`));
        const yieldColor = parseInt(sAction.dataGet(`${data.prefix}/yield_color`));
        const tonerBlackOriginal = parseFloat(sAction.dataGet(`${data.prefix}/cost_price_toner_black_original`));
        const newTonerBlack = tonerBlackOriginal + (((warrantyExtensionBase * tonerConstant * discount / 100) / ((2000 / yieldBlackWhite) + (!colorToner ? 0 : 500 / yieldColor))));

        sAction.dsAdd('set', `${data.prefix}/cost_price_toner_black`, newTonerBlack.toFixed(2));

        // Toner cyan
        const tonerCyanOriginal = parseFloat(sAction.dataGet(`${data.prefix}/cost_price_toner_cyan_original`));
        const newTonerCyan = tonerCyanOriginal + (warrantyExtensionBase * 0.25 * discount / 100) / (500 / yieldColor);

        sAction.dsAdd('set', `${data.prefix}/cost_price_toner_cyan`, newTonerCyan.toFixed(2));

        // Toner magenta
        const tonerMagentaOriginal = parseFloat(sAction.dataGet(`${data.prefix}/cost_price_toner_cyan_original`));
        const newTonerMagenta = tonerMagentaOriginal + (warrantyExtensionBase * 0.25 * discount / 100) / (500 / yieldColor);

        sAction.dsAdd('set', `${data.prefix}/cost_price_toner_magenta`, newTonerMagenta.toFixed(2));

        // Toner yellow
        const tonerYellowOriginal = parseFloat(sAction.dataGet(`${data.prefix}/cost_price_toner_cyan_original`));
        const newTonerYellow = tonerYellowOriginal + (warrantyExtensionBase * 0.25 * discount / 100) / (500 / yieldColor);

        sAction.dsAdd('set', `${data.prefix}/cost_price_toner_yellow`, newTonerYellow.toFixed(2));

        const newCostMonthlyRent = (newTotalCostService / parseInt(monthsOfService));
        sAction.dsAdd('set', `${data.prefix}/cost_monthly_rent`, newCostMonthlyRent.toFixed(2));

        sAction.dsProcess();
    }

    // Podle typu služby funkce zobrazí nebo skryje potřebné pole
    hideFieldsByServiceType(sAction, data) {
        sAction.dsClear();

        if (data.value === 'rent_and_toner') {
            this.rentAndTonerFields.forEach((field) => {
                sAction.dsAdd('set', `${data.way}/customData/customLines/customStyle/${data.lineIndex}/${field}/display`, 'inherit');
            });
            this.rentAndClickFields.forEach((field) => {
                sAction.dsAdd('set', `${data.way}/customData/customLines/customStyle/${data.lineIndex}/${field}/display`, 'none');
            })
        }

        if (data.value === 'rent_and_click') {
            this.rentAndTonerFields.forEach((field) => {
                sAction.dsAdd('set', `${data.way}/customData/customLines/customStyle/${data.lineIndex}/${field}/display`, 'none');
            });
            this.rentAndClickFields.forEach((field) => {
                sAction.dsAdd('set', `${data.way}/customData/customLines/customStyle/${data.lineIndex}/${field}/display`, 'inherit');
            })
        }

        sAction.dsProcess();
    }

    // Přepočet polí po změné množství
    calculateFieldsWithNewAmount(sAction, data) {
        const amount = data.value;

        sAction.dsClear();

        const monthsOfService = sAction.dataGet(`${data.prefix}/months_of_service`);
        const warrantyExtension = sAction.dataGet(`${data.prefix}/service_${monthsOfService}`) || 0;
        const priceHwOnePiece = parseFloat(sAction.dataGet(`${data.prefix}/price_hw_total_one_piece`));
        const shippingCosts = parseFloat(sAction.dataGet(`${data.prefix}/shipping_costs`)) || 0;
        const discount = parseFloat(sAction.dataGet(`${data.prefix}/discount`)) || 0;

        let warrantyExtensionBase = warrantyExtension * amount;
        if (discount !== 0) {
            warrantyExtensionBase = warrantyExtensionBase - (warrantyExtensionBase * (discount / 100));
        }

        let interestMultiply = 0.165;
        if (monthsOfService === '24') {
            interestMultiply = 0.11;
        } else if (monthsOfService === '36') {
            interestMultiply = 0.165;
        } else if (monthsOfService === '48') {
            interestMultiply = 0.22;
        } else if (monthsOfService === '60') {
            interestMultiply = 0.275;
        }

        const priceAccessories = parseFloat(sAction.dataGet(`${data.prefix}/price_accessories_and_installation`)) || 0;
        const newPriceHwTotal = (priceHwOnePiece * amount) + priceAccessories;
        const newInterest = (priceHwOnePiece + priceAccessories) * amount * interestMultiply;
        const newTotalCostService = newInterest + newPriceHwTotal + warrantyExtensionBase + shippingCosts;
        const newCostMonthlyRent = (newTotalCostService / parseInt(monthsOfService));

        sAction.dsAdd('set', `${data.prefix}/interest`,newInterest.toFixed(2));
        sAction.dsAdd('set', `${data.prefix}/warranty_extension`, warrantyExtensionBase.toFixed(2));
        sAction.dsAdd('set', `${data.prefix}/total_costs_service`, newTotalCostService.toFixed(2));
        sAction.dsAdd('set', `${data.prefix}/price_hw_total`, newPriceHwTotal.toFixed(2));
        sAction.dsAdd('set', `${data.prefix}/cost_monthly_rent`, newCostMonthlyRent.toFixed(2));

        const sellMonthlyRent = sAction.dataGet(`${data.prefix}/sell_monthly_rent`);
        const newMarginHw = ((sellMonthlyRent / newCostMonthlyRent) - 1) * 100;

        sAction.dsAdd('set', `${data.prefix}/margin_hw`, newMarginHw);

        sAction.dsProcess();
    }

    // Přepočet nákladů za dopravu
    calculateShippingCosts(sAction, data) {
        sAction.dsClear();
        let amount = 1;
        if (data.field === 'amount') {
            amount = data.value;
        } else {
            amount = parseInt(sAction.dataGet(`${data.prefix}/amount`));
        }
        const monthsOfService = parseInt(sAction.dataGet(`${data.prefix}/months_of_service`));

        const yieldColor = parseFloat(sAction.dataGet(`${data.prefix}/yield_color`));
        const printColor = parseFloat(sAction.dataGet(`${data.prefix}/print_amount_color`)) || 0;
        const yieldBW = parseFloat(sAction.dataGet(`${data.prefix}/yield_black_white`));
        const printBW = parseFloat(sAction.dataGet(`${data.prefix}/print_amount_black_white`)) || 0;
        let amountColorToner = 0;
        if (yieldColor) {
            amountColorToner = Math.ceil(amount * (printColor / yieldColor) * 3 * monthsOfService);
        }
        const amountBWToner = Math.ceil(amount * (((printBW / yieldBW) * monthsOfService) + (amountColorToner / 3)));
        const shippingCosts = (amountColorToner + amountBWToner) * 80;

        sAction.dsAdd('set', `${data.prefix}/shipping_costs`, shippingCosts.toFixed(2));
        sAction.dsProcess();

        data.prevValue = amount;
        data.value = amount;
        if (data.field !== 'amount') {
            this.calculateFieldsWithNewAmount(sAction, data);
        }
    }


    // Nastaví se produkty které jsou k výberu podle zvolené kategorie
    setProductsByCategory(sAction, data, index) {
        const fieldPath = `${data.way}/customData/customLines/customLinesData/${index}/acm_calculation_products_acm_calculation_quote_lines_1_name`;
            let defaultFilter = {
                products: {
                    field: 'category',
                    value: data.value,
                    type: 'eq',
                    operandType: 'notRelate',
                },
            };
            if (!data.value) {
                defaultFilter = {};
            }
            sAction.dataSet(`${fieldPath}/defaultFilter`, defaultFilter);
    }

    // Funkce dotáhne a nastaví hodnoty z vybraného produktu
    setLineData(sAction, data) {
        const amount = sAction.dataGet(`${data.prefix}/amount`);
        const category = sAction.dataGet(`${data.prefix}/category`);
        const priceAccessories = parseFloat(sAction.dataGet(`${data.prefix}/price_accessories_and_installation`)) || 0;
        const lineId = sAction.dataGet(`${data.prefix}/acm_calcul4965roducts_ida`);

        sAction.rest.post('getProductData', {id: data.value}, (res) => {
            if (res.status) {
                // Zachovat categorie a množství a id produktu
                sAction.dataSet(data.prefix, {
                    acm_calcul4965roducts_ida: lineId,
                    category,
                    amount,
                });
                sAction.dsClear();
                Object.keys(res.data).forEach((key) => {
                    const value = res.data[key];
                    sAction.dsAdd('set', `${data.prefix}/${key}`,value);
                });
                sAction.dsAdd('set', `${data.prefix}/cost_price_toner_cyan_original`, res.data.cost_price_toner_cyan);
                sAction.dsAdd('set', `${data.prefix}/cost_price_toner_black_original`, res.data.cost_price_toner_black);
                sAction.dsAdd('set', `${data.prefix}/cost_price_print_black_white_original`, res.data.cost_price_print_black_white);
                sAction.dsAdd('set', `${data.prefix}/cost_price_print_color_original`, res.data.cost_price_print_color);
                sAction.dsAdd('set', `${data.prefix}/price_hw_total`, (res.data.price_hw_total_one_piece * amount) + priceAccessories);

                // Pokud produkt obsahuje v názvu epson schovat pole slevy
                if (res.data?.acm_calculation_products_acm_calculation_quote_lines_1_name?.toLowerCase()?.includes('epson')) {
                    sAction.dsAdd('set', `${data.way}/customData/customLines/customStyle/${data.lineIndex}/discount/display`, 'none');
                }

                sAction.dsProcess();
            }
        });
    }

    // Počítá marži pro čb
    // Buď počátá podle ceny za toner nebo podle ceny za klik
    calcMarginBlackWhite(data, serviceType, sAction) {
        if (data.field === 'sell_price_print_black_white' && serviceType === 'rent_and_click') {
            const bwSell = sAction.dataGet(`${data.prefix}/sell_price_print_black_white`);
            const bwCost = sAction.dataGet(`${data.prefix}/cost_price_print_black_white`);
            const result = ((bwSell / bwCost) -1) * 100;

            sAction.dataSet(`${data.prefix}/margin_print_black_white`, result);
        } else if (data.field === 'sell_price_toner_black' && serviceType === 'rent_and_toner') {
            const bwSell = sAction.dataGet(`${data.prefix}/sell_price_toner_black`);
            const bwCost = sAction.dataGet(`${data.prefix}/cost_price_toner_black`);
            const result = ((bwSell / bwCost) -1) * 100;

            sAction.dataSet(`${data.prefix}/margin_print_black_white`, result);
        }
    }

    // Počítá marži pro barvu
    // Buď počátá podle ceny za toner nebo podle ceny za klik
    calcMarginColor(data, serviceType, sAction) {
        if (data.field === 'sell_price_print_color' && serviceType === 'rent_and_click') {
            const colorSell = sAction.dataGet(`${data.prefix}/sell_price_print_color`);
            const colorCost = sAction.dataGet(`${data.prefix}/cost_price_print_color`);
            const result = ((colorSell / colorCost) -1) * 100;

            sAction.dataSet(`${data.prefix}/margin_print_color`, result);
        } else if (data.field === 'sell_price_toner_cyan' && serviceType === 'rent_and_toner') {
            const colorSell = sAction.dataGet(`${data.prefix}/sell_price_toner_cyan`);
            const colorCost = sAction.dataGet(`${data.prefix}/cost_price_toner_cyan`);
            const result = ((colorSell / colorCost) -1) * 100;

            sAction.dataSet(`${data.prefix}/margin_print_color`, result);
        }
    }
}
