
import $ from "jquery";
import detailDefault from "../../detailDefault";
export default class detailCalls extends detailDefault{
    load(sAction,data) {
        this.setNotInterestedField(sAction, data);
        this.setNotInterestedReasonField(sAction, data);

      if(!sAction.dataGet(data.prefix+"/id")){
        const parent_type = sAction.dataGet(data.prefix+"/fields/parent_name/def/parent_type_value");
        const parent_id = sAction.dataGet(data.prefix+"/fields/parent_name/def/id_value");
        if(parent_id && (parent_type == "Accounts" || parent_type == "Contacts")){
          this.setContactinfo(sAction, {module : parent_type, id : parent_id}, data.prefix);
        }
      }

      var prefixInv = data.prefix+"/customData/invitees";
      
      if(data.view == "calendar" || data.view == "home"){
        prefixInv = data.prefix.replace("/detailView", "");
        prefixInv += "/invitees";
      }  

      const parent = sAction.dataGet(data.prefix + "/fields/parent_name");
      if (parent && parent.def && parent.def.get("parent_type_value") == "Opportunities" && parent.def.get("id_value") != "") {
        this.checkParenOpp(sAction, data, parent);
      }

      this.searchInvitees(sAction, data.prefix, prefixInv);

      if(data.view != "calendar" && data.view != "home"){
        const id = sAction.dataGet(data.prefix+"/id");
        if(!id){
          const date = new Date();
          const year = date.getFullYear();
          var month = date.getMonth()+1;
          if(month < 10){
            month = "0"+month
          }
          var day = date.getDate();
          var hours = date.getHours();
          var minutes = date.getMinutes();
          if (minutes > 44) {
            minutes = 0;
            hours++;
            if (hours > 23) {
              hours = 0;
              day++;
            }
          }
          if (day < 10) {
            day = "0" + day;
          }
          if (hours < 10) {
            hours = "0" + hours;
          }
          minutes = Math.round(minutes / 30) * 30;
          if(minutes < 10){
            minutes = "0"+minutes
          }

          //Kontrola zda nejsou ulozene hodiny a minuty aby zustali i pri duplikaci.

          const savedHours = sAction.dataGet(data.prefix+"/fields/duration_hours/value")

          let innitValueHours = 0;
          let innitValuesMinutes = 15;

          if(savedHours) {
            innitValueHours = savedHours
            innitValuesMinutes = sAction.dataGet(data.prefix+"/fields/duration_hours/def/minutesValue")
          }

          const today = year+"-"+month+"-"+day+" "+hours+":"+minutes+":00";
          sAction.dsClear();
          sAction.dsAdd("set",data.prefix+"/fields/date_start/value",today)
          sAction.dsAdd("set",data.prefix+"/changes/fields/date_start",today)
          sAction.dsAdd("set",data.prefix+"/fields/duration_hours/value",innitValueHours)
          sAction.dsAdd("set",data.prefix+"/fields/duration_hours/def/minutesValue",innitValuesMinutes)
          sAction.dsAdd("set",data.prefix+"/changes/fields/duration_hours",innitValueHours)
          sAction.dsAdd("set",data.prefix+"/changes/fields/duration_minutes",innitValuesMinutes)
          sAction.dsAdd("set",data.prefix+"/fields/direction/value","Outbound")
          sAction.dsAdd("set",data.prefix+"/changes/fields/direction","Outbound")
          sAction.dsProcess();
        }
      }
    }
    update(sAction,data) {
      if(data.field == "parent_id"){
        const parent_type = sAction.dataGet(data.prefix+"/fields/parent_name/def/parent_type_value");
        if(parent_type == "Accounts" || parent_type == "Contacts"){
          if(data.value.id){
            this.setContactinfo(sAction, {module : parent_type, id : data.value.id}, data.prefix);
          }
        }

        var prefixInv = data.prefix+"/customData/invitees";
        if(data.view == "calendar" || data.view == "home"){
          var prefixInv = data.prefix.replace("/detailView", "").replace("/callView", "");
          prefixInv += "/invitees";
        }
        else if(data.view != "detail"){
          return;
        }
        this.searchInvitees(sAction, data.prefix, prefixInv);
        const parent = sAction.dataGet(data.prefix + "/fields/parent_name");
        if (parent && parent.def && parent.def.get("parent_type_value") == "Opportunities" && parent.def.get("id_value") != "") {
          this.checkParenOpp(sAction, data, parent);
        }
      } else if (data.field === 'status') {
          this.setNotInterestedField(sAction, data, data.value);
      } else if (data.field === 'not_interested') {
          this.setNotInterestedReasonField(sAction, data, data.value);
      }
    }
    searchInvitees(sAction, prefix, prefixInv){
      const parent = sAction.dataGet(prefix+"/fields/parent_name");
      if(parent && parent.def && parent.def.get("parent_type_value") == "Accounts" && parent.def.get("id_value") != ""){
        sAction.searchInvitees({parent: parent.toJS()}, prefixInv); 
      }
    }

    setContactinfo(sAction, params, prefix){
      params.action = "getActivityContactInfo";
      sAction.rest.post("customAction", params, function(resp) {
        if(resp.errCode == "OK"){
            sAction.dsClear();
            sAction.dsAdd("set",prefix+"/fields/phone_c/value", resp.data.phone)
            sAction.dsAdd("set",prefix+"/changes/fields/phone_c", resp.data.phone)
            sAction.dsAdd("set",prefix+"/fields/mobile_c/value", resp.data.mobile)
            sAction.dsAdd("set",prefix+"/changes/fields/mobile_c",resp.data.mobile)
            sAction.dsProcess();
            
        }
      });
    }

    checkParenOpp(sAction, data, parent){
      let parName = parent.value;
      let parId = parent.def.get("id_value");

      let oppId = sAction.dataGet(data.prefix + "/fields/opportunities_calls_1_name/def/id_value");
      if(oppId !== parId){
        sAction.dsClear();
        sAction.dsAdd("set", data.prefix + "/fields/opportunities_calls_1_name/def/id_value", parId);
        sAction.dsAdd("set", data.prefix + "/fields/opportunities_calls_1_name/value", parName);
        sAction.dsAdd("set", data.prefix + "/changes/fields/opportunities_calls_1opportunities_ida", parId);
        sAction.dsProcess();
      }
    }

    setNotInterestedField(sAction, data, value = null) {
        value = value ?? sAction.dataGet(`${data.prefix}/fields/status/value`)
        sAction.dsClear();
        sAction.dsAdd('set', `${data.prefix}/fields/not_interested/def/required`, value === 'Held');
        sAction.dsProcess();
    }
    setNotInterestedReasonField(sAction, data, value = null) {
        value = value ?? sAction.dataGet(`${data.prefix}/fields/not_interested/value`)
        sAction.dsClear();
        sAction.dsAdd('set', `${data.prefix}/fields/not_interested_reason/def/required`, value === 'no');
        sAction.dsProcess();
    }
}
