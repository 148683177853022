
import $ from "jquery";
import detailDefault from "../../detailDefault";
export default class detailOpportunities extends detailDefault{
    load(sAction, data) {

        const parentId = sAction.dataGet(data.prefix+"/fields/account_name/def/id_value");
        const record = sAction.dataGet(data.prefix+"/id");

        if(parentId){
            const filter = {
                parent: {
                    operandType: "relate",
                    parentId: parentId,
                    module: "Accounts",
                    relationship: ["accounts"],
                    name: "account_name",
                    relName: "contacts",
                    type: "eq"
                  }
            }

            sAction.dataSet(data.prefix+"/fields/contacts_opportunities_1_name/def/defaultFilter", filter);
        }

        sAction.dsClear();
        const sales_stage = sAction.dataGet(data.prefix+"/fields/sales_stage/value");
        if(!record || sales_stage == "new"){
            sAction.dsAdd("set", data.prefix+"/fields/contacts_opportunities_1_name/def/required", false);
        }

        if( sales_stage == "Closed Lost" ||
            sales_stage == "Closed canceled client" ||
            sales_stage == "Closed canceled me"
            ){
                sAction.dsAdd("set", data.prefix+"/fields/duvod_uzavreni_c/def/readonly", false);
                sAction.dsAdd("set", data.prefix+"/fields/duvod_uzavreni_c/def/required", true);
                sAction.dsAdd("set", data.prefix+"/fields/pripominky_k_uzavreni_c/def/readonly", false);
            }
            else{
                if(sales_stage == "Closed Won"){
                    sAction.dsAdd("set", data.prefix+"/fields/duvod_uzavreni_c/def/readonly", false);
                    //$("#duvod_uzavreni_c").closest(".detailViwRow").css("visibility", "hidden");
                    //sAction.dsAdd("set", data.prefix+"/fields/duvod_uzavreni_c/def/visible", false);
                }
                else{
                    sAction.dsAdd("set", data.prefix+"/fields/duvod_uzavreni_c/def/readonly", true);
                    //$("#duvod_uzavreni_c").closest(".detailViewTabContentRow").prevAll(".detailViewPanelHeader:first").css("display", "none");
                    //$("#duvod_uzavreni_c").closest(".detailViewTabContentRow").css("display", "none");
                }
                sAction.dsAdd("set", data.prefix+"/fields/duvod_uzavreni_c/def/required", false);
            }

            if(sAction.getModuleName() == "Contacts" && data.prefix != "view"){
                const accData = sAction.dataGet("view/fields/account_name");
                const name = accData.get("value");
                const id = accData.getIn(["def","id_value"])
                if(id){
                sAction.dsAdd("set", data.prefix+"/fields/account_name/value", name);
                sAction.dsAdd("set", data.prefix+"/fields/account_name/def/id_value", id);
                sAction.dsAdd("set", data.prefix+"/changes/fields/account_id", id);
                }
            }
            sAction.dsProcess();
    }

    update(sAction,data) {
        if(data.field == "account_id"){

            if(data.value.id){
                const filter = {
                    parent: {
                        operandType: "relate",
                        parentId: data.value.id,
                        module: "Accounts",
                        relationship: ["accounts"],
                        name: "account_name",
                        relName: "contacts",
                        type: "eq"
                        }
                }

                sAction.dataSet(data.prefix+"/fields/contacts_opportunities_1_name/def/defaultFilter", filter);
            }
            else{
                sAction.dataSet(data.prefix+"/fields/contacts_opportunities_1_name/def/defaultFilter", null);
            }

            const name = sAction.dataGet(data.prefix+"/fields/name/value");
            if(name == null || name == ""){
                const nameAcc = sAction.dataGet(data.prefix+"/fields/account_name/value");
                sAction.dsClear();
                sAction.dsAdd("set",data.prefix+"/fields/name/value",nameAcc);
                sAction.dsAdd("set",data.prefix+"/changes/fields/name",nameAcc);
                sAction.dsProcess();
            }
        }
        else if(data.field == "sales_stage"){
            if( data.value == "Closed Lost" ||
                data.value == "Closed canceled client" ||
                data.value == "Closed canceled me"
            ){
                //$("#duvod_uzavreni_c").closest(".detailViewTabContentRow").prevAll(".detailViewPanelHeader:first").css("display", "flex");
                //$("#duvod_uzavreni_c").closest(".detailViewTabContentRow").css("display", "flex");
                //$("#duvod_uzavreni_c").closest(".detailViwRow").css("visibility", "visible");

                sAction.dsClear();
                sAction.dsAdd("set", data.prefix+"/fields/duvod_uzavreni_c/def/readonly", false);
                sAction.dsAdd("set", data.prefix+"/fields/duvod_uzavreni_c/def/required", true);
                sAction.dsAdd("set", data.prefix+"/fields/pripominky_k_uzavreni_c/def/readonly", false);


                sAction.dsProcess();
            }
            else{
                sAction.dsClear();
                if(data.value == "Closed Won"){
                    sAction.dsAdd("set", data.prefix+"/fields/pripominky_k_uzavreni_c/def/readonly", false);
                    //$("#duvod_uzavreni_c").closest(".detailViewTabContentRow").prevAll(".detailViewPanelHeader:first").css("display", "flex");
                    //$("#duvod_uzavreni_c").closest(".detailViewTabContentRow").css("display", "flex");
                    //$("#duvod_uzavreni_c").closest(".detailViwRow").css("visibility", "hidden");
                }
                else{
                    sAction.dsAdd("set", data.prefix+"/fields/pripominky_k_uzavreni_c/def/readonly", true);
                    //$("#duvod_uzavreni_c").closest(".detailViewTabContentRow").prevAll(".detailViewPanelHeader:first").css("display", "none");
                    //$("#duvod_uzavreni_c").closest(".detailViewTabContentRow").css("display", "none");

                }



                sAction.dsAdd("set", data.prefix+"/fields/duvod_uzavreni_c/def/required", false);
                sAction.dsProcess();

            }
        } else if(data.field === "contract_months" || data.field === "amount") {
            let contractMonths = sAction.dataGet(data.prefix+"/fields/contract_months/value");
            console.log(contractMonths)
            contractMonths = (contractMonths != 0 && contractMonths != null) ? contractMonths : 1;
            const amount = sAction.dataGet(data.prefix+"/fields/amount/value");
            const monthlyAmount = amount / contractMonths;
            sAction.dataSet(data.prefix+"/fields/monthly_amount/value", monthlyAmount);
            sAction.dataSet(data.prefix+"/changes/fields/monthly_amount", monthlyAmount);
        }

    }

}
