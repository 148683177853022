export default function defaultListViewEditSave(data,way){

    const columns = data.get("columns")
    const activeColumns = columns.get("active")
    const columnsToServer = [];
    activeColumns.forEach(column => {
        if (column.def != undefined) {
          var relationship = column.def.get("relationship");
          if (relationship != module) {
            if (
              relationship != undefined &&
              relationship != null &&
              relationship != ""
            ) {
              relationship = relationship.split("/*");
            }
          } else {
            relationship = undefined;
          }
  
          columnsToServer.push({
            field: column.name,
            module: column.def.get("fieldModule"),
            width: column.def.get("width"),
            relationship: relationship
          });
        }
      });

    const params = {
        columns: columnsToServer,
        module: data.get("module"),
        listview_type: data.get("listview_type")
    }
    if(data.get("listview_type") == 'subpanel'){
      params["parent"] = data.get("parent")
    }
    this.load()
    this.rest.post("defaultListViewSave",params,ret => {
        if(ret.state == true){
          this.popupHide();
          if (params["listview_type"] === 'popup') {
            this.selectLook(" ", "conf/popup/data/listView");
          } else if (params["listview_type"] === 'list') {
            localStorage.setItem(params["module"] + "_saved_search", " ");
            this.selectLook(" ", "view");
          } else {
            this.route();
          }
        }
    }) 
}