export default function gusAPI(sendData, prefix = "view") {
    if (!sendData.nip) {
        this.error(this
            .translate("LBL_CHECK_DIC_MSGERR_NODIC", "Accounts"), this.translate("LBL_CHECK_DIC_MSGERR_ENTERDIC", "Accounts"));
        return;
    }

    this.load();
    this.rest.get(`detailapi/gusapi/${sendData.nip}`, returnData => {
        this.unLoad();
        if (returnData.status) {
            this.dsClear();
            returnData.message?.data?.[0]?.forEachObject?.((fieldValue, fieldName) => {
                this.dsAdd('set', `${prefix}/fields/${fieldName}/value`, fieldValue);
                this.dsAdd('set', `${prefix}/changes/fields/${fieldName}`, fieldValue);
            });
            this.dsProcess();
        } else {
            this.error(this.translate(returnData?.errorMessage?.text));
        }
    });
}
