import React from "react";
import moment from 'moment';
import FilterBasic from './filterBasic'

import  sAction  from "sAction";
import Select from "../../formElements/Select";
import AcmDate from "../../formElements/AcmDate";

export default class FilterDate extends FilterBasic {
    constructor(props) {
        super(props);

        const actFilter = this.props.actFilter;
        var lines = [];
        if (actFilter && actFilter.filters) {
          actFilter.filters.forEach((line, key) => {
            lines[key] = {
              select: line.type,
              input: line.value,
            };
          });
        }
        this.state = {
            lines: lines,
            disabled: false
        };

        this.input = [];
        this.customOperator = React.createRef();
    }

    addCustomFilterLineToView() {
        const data = this.props.data;
        const prefix = this.props.prefix;
        const way = this.props.way;
        const index = this.props.index;
        if (prefix == way) {
            sAction.dsClear();
            sAction.dsAdd("add", prefix + "/filter", { field: data.get("name"), operator: "and", actCustom: true }, "filterGroup");
            sAction.dsAdd("add", prefix + "/filter/" + index + "/filters", { type: null, value: "" }, "filterParameter");
            sAction.dsProcess();
        } else {
            sAction.dsClear();
            sAction.dsAdd("add", way + "/filters", { type: null, value: "" }, "filterParameter");
            sAction.dsProcess();
        }
    }
    deleteCustomFilterLineFromView(key) {
        const way = this.props.way;
        sAction.dataDelete(way + "/filters", key);
    }

    customOption() {
        this.props.parent.advanced();
        const data = this.props.data;
        const prefix = this.props.prefix;
        const way = this.props.way;
        const index = this.props.index;
        if (prefix == way) {
            sAction.dsClear();
            sAction.dsAdd("add", prefix + "/filter", { field: data.get("name"), operator: "and", actCustom: true }, "filterGroup");
            sAction.dsAdd("add", prefix + "/filter/" + index + "/filters", { type: null, value: "" }, "filterParameter");
            sAction.dsProcess();
        }
    }

    addCustomFilter(field) {
        const prefix = this.props.prefix;
        var filters = new Array();
        var lines = this.state.lines;
        lines.forEach(line => {
            const select = line.select;
            const text = line.input;
            var filter = {
                field: field,
                type: select,
                value: text
            };
            filters.push(filter);
        });
        const operator = this.customOperator.current.value;
        const data = {
            field,
            filters,
            operator,
            prefix,
            reload: true
        };
        sAction.addFilter(data);
    }

    updateLines(key, value, input) {

        var newLine = this.state.lines.slice(0);
        var property = newLine[key] ? newLine[key] : {};
        property[input] = value;
        newLine[key] = property;
        this.setState({
            lines: newLine
        })
    }

    onKeyDown(event) {
        const data = this.props.data
        if (event.keyCode == 13) {
            this.addCustomFilter(data.get("name"))
        }
    }

    render() {

        const data = this.props.data;
        const actFilter = this.props.actFilter;
        var custom = null;
        if (actFilter == null || actFilter.actCustom == false) {
            custom = (
                <div onClick={() => this.customOption()} className="filterButton">
                    {sAction.translate("LBL_CUSTOM_FILTER")}
                </div>
            );
        } else {
            var customLines = [];
            const dateOptions = sAction.getDateFilterOptions();

            if (actFilter != null) {
                actFilter.filters.forEach((line, key) => {
                    var select = (
                        <Select
                            key={actFilter.field + "_sekect"}
                            defaultValue={line.type}
                            options={dateOptions}
                            onChange={(e) => {
                                this.updateLines(key, e.target.value, 'select')
                                this.input[key] = e.target.value
                            }}
                        />
                    );

                    var disabledOptions = [];
                    dateOptions.forEach((value) => {
                        disabledOptions[value.value] = value.disabledInput;
                    });
                    var disabled = disabledOptions[this.input[key]];
                    var defaultValue = line.value ? line.value : null;

                    customLines.push(
                        <div className="filterLine" key={key}>
                            {select}

                            <AcmDate
                                key={actFilter.field + "_input"}
                                onChange={(e) =>
                                    this.updateLines(key, e, 'input')
                                }
                                disabled={disabled}
                                onKeyDown={(e) => { this.onKeyDown(e) }}
                                value={defaultValue}
                            />
                            {customLines.length != 0 && (
                                <div
                                    className="customFilterDeleteRow iconCancel"
                                    onClick={() => this.deleteCustomFilterLineFromView(key)}
                                />
                            )}
                        </div>
                    );
                });
            }
            custom = (
                <div className="customFilter">
                    <div className="customFilterheader">
                        {sAction.translate("LBL_CUSTOM_FILTER")}
                    </div>
                    <div className="CustomFilterLinesContent">
                        <div className="CustomFilterLines">
                            <div ref="customLines">{customLines}</div>
                            <div
                                className="filterLine customFilterAddLineButton"
                                onClick={() => this.addCustomFilterLineToView()}
                            >
                                +
              </div>
                        </div>
                        <div className="customFilterLinesOperator">
                            <Select
                                key={actFilter.field + "_groupSelect"}
                                defaultValue={actFilter.operator}
                                myRef={this.customOperator}
                                options={[{value: 'and', label: sAction.translate("LBL_AND")},{value: 'or', label: sAction.translate("LBL_OR")}]}
                            />
                        </div>
                    </div>

                    <div
                        tabIndex="2"
                        onClick={() => this.addCustomFilter(data.get("name"))}
                        className="filterCustomFilterButton"
                    >
                        {sAction.translate("LBL_LIST_FILTER")}
                    </div>
                </div>
            );
        }
        var removeFilterButton = null;
        if (actFilter != null) {
            removeFilterButton = (
                <div
                    onClick={() => this.removeFilter(data.get("name"))}
                    className="filterButton cancelFilterButton"
                >
                    {sAction.translate("LBL_DELETE_FILTER")}
                </div>
            );
        }

        return (
            <div>
                <div
                    onClick={() => this.order(data.get("name"), 0)}
                    className="filterButton"
                >
                    {sAction.translate("LBL_ORDER_NEW")}
                </div>
                <div
                    onClick={() => this.order(data.get("name"), 1)}
                    className="filterButton"
                >
                    {sAction.translate("LBL_ORDER_OLD")}
                </div>
                {custom}
                {removeFilterButton}
            </div>
        );
    }
}
