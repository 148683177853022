import moment from 'moment';
export default function calActivityHelper(params){
    var self = this;
    const prefix = params.prefix;

    if(params.type == "DELETE"){
        const id = params.id;
        const module = params.module;

        
        this.rest.delete("delete/"+module+"/"+id, {},() => {
            self.popupHide(self.clearCalDetailStore.bind(null, params.pomWay));

            if(prefix.indexOf("calendar") !== -1){
                self.calGetEvents(true);
              }
              else{
                //const pomWay = prefix.substr(0, prefix.indexOf('/data/viewData/'));
                const attrs = {
                  action : "refresh",
                  cssSelector : "#dashFC_"+self.dataGet(prefix+"/../dashletId"),
                  way: prefix+"/../../.."
                };
                self.calActivityDashlet(attrs);
              }
        });
    }
    else if(params.type == "CANCEL"){
        self.popupHide(self.clearCalDetailStore.bind(null, params.pomWay));
    }
    else if(params.type == "FULLFORM"){
        const id = params.id;
        const module = params.module;

        window.open("#detail/"+module+"/"+id, '_blank');
    }  
    else if(params.type == "COMPLETEANDCREATENEW"){

        const pomPrefix = prefix.substr(0, prefix.indexOf('/detailView'));

        self.saveField({way: prefix+"/fields/status", name: "status", value: params.compValue}, true);
        self.calSaveActivityDetailHelper(params, function(data) {
            if (data.state == true) {

                self.detailPredefinedFields = [];
    
                const ignoreFields = [
                "id",
                "created_by_name",
                "date_entered",
                "date_modified",
                "date_start",
                "date_end",
                "duration_hours",
                "status",
                ];

                const fields = self.dataGet(prefix+"/fields").toJS();
            
                fields.forEachObject( (field, fname) => {
                if(ignoreFields.indexOf(fname) >= 0){
                    return;
                }

                if(field.def.type == "parent"){
                    self.detailPredefinedFields.push({
                    name: "parent_name", 
                    id_name: "parent_id", 
                    parentName: field.value, 
                    parentId: field.def.id_value, 
                    parentModule: field.def.parent_type_value, 
                    type: "parent"
                    });
                }
                else{
                    self.detailPredefinedFields.push({name: fname, value: field.value, type: "field"});
                }
                });

                var newPrefix = "calendar/detailView";
                var target = "";
                let header = "";
                let color = "";
                let icon = "";
               
                switch(params.module){
                    case "Meetings":
                        target = "detailMeeting";
                        newPrefix = params.pomWay+"/meetingView";
                        header = self.translate("LBL_TITLE_CREATE_NEW_MEETING", "Calendar");
                        color = "MeetingsCalTitle";
                        icon = "icon-Meetings";
                        break;
                    case "Calls":
                        target = "detailCall";
                        newPrefix = params.pomWay+"/callView";
                        header = self.translate("LBL_TITLE_CREATE_NEW_CALL", "Calendar");
                        color = "CallsCalTitle";
                        icon = "icon-Calls";
                        break;
                    case "Tasks":
                        target = "detailTask";
                        newPrefix = params.pomWay+"/taskView";
                        header = self.translate("LBL_TITLE_CREATE_NEW_TASK", "Calendar");
                        color = "TasksCalTitle";
                        icon = "icon-Tasks";
                        break;
                }

                self.dataSet(pomPrefix+"/activeTab", target);

                const date = moment();
                const start = date.format("YYYY-MM-DD hh:mm:ss");
                const end = date.add(15, 'minutes').format("YYYY-MM-DD hh:mm:ss");

                if(params.module == "Meetings" || params.module == "Calls"){

                    self.saveField({way: newPrefix+"/fields/date_start", name: "date_start", value: start}, true);
                    self.saveField({way: newPrefix+"/fields/duration_hours", name: "duration_hours", value: "0", valueMinutes: 15, type:"hourseMinutes"}, true);
                    self.saveField({way: newPrefix+"/fields/date_end", name: "date_end", value: end}, true);
                    
                    

                    self.detailPredefinedFields.push({name: "status", value: "Planned", type: "field"});
                    self.detailPredefinedFields.push({name: "date_start", value: start, type: "field"});
                    self.detailPredefinedFields.push({name: "duration_hours", value: "0", type: "field"});
                    self.detailPredefinedFields.push({name: "duration_minutes", value: "15", type: "field"});
                    self.detailPredefinedFields.push({name: "date_end", value: end, type: "field"});
                    
                    
                }
                else{

                    self.detailPredefinedFields.push({name: "status", value: "Not Started", type: "field"});

                    self.saveField({way: newPrefix+"/fields/date_due", name: "date_due", value: end}, true);
                    self.saveField({way: newPrefix+"/fields/date_start", name: "date_start", value: start}, true);
                    
                }

                self.detailPredefinedFields.forEachObject( (field) => {
                    if(field.type == "parent"){
                        
                        self.saveField({way: newPrefix+"/fields/"+field.name, 
                        type: "parent",
                        name: field.name, 
                        value: {id: field.parentId, name: field.parentName}, 
                        parent_type: field.parentModule}, 
                        true);
                        self.dataSet(newPrefix+"/changes/fields/parent_id", field.parentId);
                        self.dataSet(newPrefix+"/changes/fields/parent_type", field.parentModule);
                        

                        
                    }
                    else{
                        self.saveField({way: newPrefix+"/fields/"+field.name, name: field.name, value: field.value}, true);
                    }
                });

                self.dsClear();

                let action = {
                    type: "CALENDARINITACTIVITY",
                    content: {invitees : {Users: [], Contacts: [], Leads : []}},
                    prefix: pomPrefix+"/invitees/relatedInv"
                }
                self.dsAddCustom(action);
                self.dsProcess();
                self.popup("calQuickCreate", {onClose : self.clearCalDetailStore.bind(null, params.pomWay), prefix : pomPrefix, header, color, icon});
            }else{
              self.error(data.msg);
            }
         });
        
    }
    else if(params.type == "COMPLETEANDCREATENEWOPP"){
        self.saveField({way: prefix+"/fields/status", name: "status", value: params.compValue}, true);
        self.calSaveActivityDetailHelper(params, function(data) {
            if (data.state == true) {
        
                self.dataSet(prefix+"/changes", {fields: [], files: []});
        
                self.detailPredefinedFields = [];
        
                self.detailPredefinedFields.push({name: "sales_stage", value: "new", type: "field"});
                if(params.parentModule){
                    self.detailPredefinedFields.push({
                        type: "customData",
                        prefix: "parentModule",
                        customData: params.parentModule,
                      });
                }
                
                
            
                const ignoreFields = [
                  "id",
                  "created_by_name",
                  "date_entered",
                  "date_modified",
                  "date_start",
                  "date_end",
                  "duration_hours",
                  "status",
                ];
        
                const fields = self.dataGet(prefix+"/fields").toJS();
            
                fields.forEachObject( (field, fname) => {
                  if(ignoreFields.indexOf(fname) >= 0){
                    return;
                  }
                  if(!field.def){
                    return;
                  }
                  
                  if(field.def.type == "parent"){

                    if(field.def.parent_type_value === "Accounts"){
                        self.detailPredefinedFields.push({
                        type: "relate_simple",
                        name: "account_id",
                        fieldName: "account_name",
                        value: { name: field.value, id: field.def.id_value }
                        });
                    }
                    

                  }
                  else{
                    self.detailPredefinedFields.push({name: fname, value: field.value, type: "field"});
                  }
                });
                
                window.location.hash = "#detail/Opportunities";
 
                
              } else {
                self.error(data.msg);
              }
        });

    }
    else if(params.type == "CREATEACTIVITY"){
        let target = "";

        const start = params.date.format("YYYY-MM-DD hh:mm:ss");
        const end = params.date.add(15, 'minutes').format("YYYY-MM-DD hh:mm:ss");

        switch(params.module){
            case "Meetings":
                target = "detailMeeting";
                self.saveField({way: "calendar/meetingView/fields/date_start", name: "date_start", value: start}, true);
                self.saveField({way: "calendar/meetingView/fields/duration_hours", name: "duration_hours", value: "0", valueMinutes: 15, type:"hourseMinutes"}, true);
                break;
            case "Calls":
                target = "detailCall";
                self.saveField({way: "calendar/callView/fields/date_start", name: "date_start", value: start}, true);
                self.saveField({way: "calendar/callView/fields/duration_hours", name: "duration_hours", value: "0", valueMinutes: 15, type:"hourseMinutes"}, true);
                break;
            case "Tasks":
                target = "detailTask";
                self.saveField({way: "calendar/taskView/fields/date_start", name: "date_start", value: start}, true);
                self.saveField({way: "calendar/taskView/fields/date_due", name: "date_due", value: end}, true);
                break;
        }

        self.dataSet("calendar/activeTab", target);
        //const currentUserInv = self.dataGet("calendar/setting/currentUserInv");

        self.dsClear();
        let action = {
            type: "CALENDARINITACTIVITY",
            content: {invitees : {Users: [], Contacts: [], Leads : []}},
            prefix: "calendar/invitees/relatedInv"
        }
        self.dsAddCustom(action);
        self.dsProcess();
        self.popup("calQuickCreate", {onClose : self.clearCalDetailStore.bind(null, params.pomWay), prefix: "calendar"});
    }

}