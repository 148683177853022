import {Record,List, Map} from 'immutable'
export var DataConstructors = {};

// _____________________________ GLOBAL SEARCH
DataConstructors.globalSearch = new Record({
    results: List(),
    searchString: ""
})

//______________________________ LEAD CONVERT
DataConstructors.leadConvert = new Record({
    detailViews: List(),
    record: null,
    name: null,
    action: null,
    newBeans: List()
})

// _____________________________ QUOTE CUSTOM DATA
DataConstructors.productData = new Record({
    groups: List(),
    currency_id: "-99",
    shipping: null,
    cenotvorba: "",
    total: null,
    dph : null
})

DataConstructors.productGroup = new Record({
    id: null,
    name: null,
    type: null,
    description: null,
    bundle_stage: null,
    cenotvorba: "",
    cenotvorba_discount : "",
    cenotvorba_discount_select : "",
    lines: List(),
    lines2 : List()
})

DataConstructors.permisisonAccess = new Record({
    roles: List(),
    users: List(),
    allowAll: false,
})

DataConstructors.product = new Record({
    id: null,
    name: "",
    quantity: "1", 
    cost_price: "",
    minimum_price: "", 
    list_price: "",
    discount_price: "",
    discount_amount: "",
    discount_select: 0,
    mft_part_num_int: "",
    mft_part_num: "",
    tax_class: "",
    opce_c: 0,
    product_template_id : "",
    category_id : "",
    description: "",
    picture: ""
})

DataConstructors.productField = new Record({
  name: "",
  type: "",
  lbl: "",
  disabled: "",
  width: ""
})

DataConstructors.leadConvertCustomData = new Record({
    beanMark: null,
    checked: null,
    required : null,
    related_id : null,
    related_name : null,
    customClass : "",
    candidates: List()
})


// _____________________________ CONF RECORDS
DataConstructors.SweetSpot = new Record({
  show:false,
  length: 0,
  data: null
})
DataConstructors.Popup = new Record({
    show:false,
    content: null,
    data: new Map(),
  })
DataConstructors.User = new Record({
    id: null,
    name: null,
    user_name: null,
    is_admin: null, // pro HomeSetView
    is_superUser: null,
    loginAs: false,
    records_per_page: null,
    email:null,
  })
DataConstructors.conf = new Record({
    view: "",
    page: "",
    load: false,
    language: "cs_CZ",
    front_version: null,
    back_version: null,
    softwareName: null,
    recordsPerPage: null,
    alertMessage : new Map(),
    popup :  DataConstructors.Popup(),
    popup2 :  DataConstructors.Popup(),
    popper: List(),
    toast: List(),
    user:  DataConstructors.User(),
    redirect: null,
});
// _____________________________ CONF RECORDS END

// _____________________________ DETAIL RECORDS 
DataConstructors.DetailView = new Record({
    id: null,
    module: null,
    mode:"detail",
    editFieldsPosition: new Map({a:null,b:null}),
    editedFieldsVname: new Map(),
    hiddenFieldButton: false,
    hiddenFields: List(),
    name: null,
    boundModules: List(),
    rowCount: null,
    editRecord: false,
    changes: new Map({fields: new Map(), files: List(), customData: new Map(), forceChange: false}),
    canSave: true,
    fields: new Map(),
    activeTab: 0,
    tabs: List(),
    subpanels: List(),
    buttons: List(),
    customJS: null,
    customData: null,
    customDataTop: null, 
    timeline: null,
    dashboard: null,
    type: null,
    activities: null,
    imageActions: Map(),
    readonly: false,
    doCustom: true,
    acl: new Map(),
    tags: List(),
  })
DataConstructors.Field = new Record({
    name: null,
    value: null,
    actEdit: false,
    customStyle: null,
    customClass: null,
    def: null,
    events: List([]),
    selectForEdit: false,
  })
DataConstructors.Tab = new Record({
    id: null,
    name: null,
    type: null,
    active: null,
    hidden: false, // pro dynamicke skryvani/odkryvani tabu za pochodu dle aktualne vybranych dat na zaznamu
    rows: List(),
  })
  DataConstructors.DetailButton = new Record({
    id: null,
    def: new Map(),
  });
  
  DataConstructors.Event = new Record({key:null,functionName:null})
// _____________________________ DETAIL RECORDS END

// _____________________________ SUBPANEL RECORDS
DataConstructors.subpanelDef = new Record({
    module: null,
    def: new Map({
      show: true,
      open: true,
      load: false,
    }),
    rows: List(),
})
// _____________________________ SUBPANEL RECORDS END

// _____________________________ LIST RECORDS
DataConstructors.ListView = new Record({modul: null,
    rows: List(),
    records: List(),
    orderBy: null,
    asc: null,
    selected: List(),
    selectedActive: true,
    selectPage: false,
    savedSearch: List(),
    actSavedSearch: null,
    actFiltering: false,
    openFilter: null,
    openFilterData: null,
    listViewTree: null,
    filter: List(),
    defaultFilter: new Map(),
    buttons: List(),
    offset: null,
    rowCount: null,
    rowTotalCount: null,
    type: null,
    limit: null,
    page: 1,
    fieldFunction: new Map(),
    colors: new Map(),
    dropZoneActive: false,
    prefix: null,
    category:null,
    customData: new Map(),
    removeFieldText: false,
    hasAssignedUser: true,
    hasUUField: false,
  });
  DataConstructors.ListViewRecord = new Record({
   id: null,
   fields: List()
  })
  DataConstructors.ListViewRecordField = new Record({
   key: null,
   value: null,
   def: null,
   events: []
 });
 DataConstructors.ListViewRow = new Record({
   key: null,
   label: null,
   def: null,
   records: null,
   width: null,
   sortable:true,
   disabled:false,
   alterName: null
 });
 DataConstructors.filterParameter = new Record({
   field: null,
   type: null,
   value: null,
   footer: false,
   parent_type: null, // only for fieldy with type parent
   fieldType: null,
   multiValue: false,
 })
 DataConstructors.filterGroup = new Record({
   field: null,
   operator: null,
   actCustom: false,
   filters: List(),
 })
 DataConstructors.ListMenuButton = new Record({
  id: null,
  def: new Map(),
 })
 DataConstructors.openFilterData = new Record({
  field: null,
  open: false,
  offset: null,
  def: new Map(),
 })
 DataConstructors.listViewTree = new Record({
   module:null,
   name:null,
   field:null,
   relationship:null,
   activeCategory: null,
   tree: null,
 })
 DataConstructors.listViewTreeCat = new Record({
  id: null,
  name: null,
  type: null,
  children: List(),
})

DataConstructors.massUpdateData = new Record({
  fields: Map(),
  selected: List(),
  module: null,
  changes: new Map({fields: new Map()}),
  prefix: "",
  filterData: null,
});
// _____________________________ LIST RECORDS END

DataConstructors.quoteProductSelector = new Record({
  listView : new DataConstructors.ListView(),
  categories: List(),
  groupWay: null,
  favorites: List(),
  recent: List(),
  top: List()

})

// _____________________________ MENU RECORDS
DataConstructors.mainMenuGroup = new Record({
  name: null,
  icon: null,
  open: false,
  buttons: List()
});
DataConstructors.mainMenu = new Record({
    groups: [],
    activeModule: null,
    open: true,
    openGroups: List(),
    actionPanel: null,
});
DataConstructors.menuButton = new Record({
    id: null,
    name: '',
    click : '',
    active: false,
    subMenuLines: null,
  });
// _____________________________ MENU RECORDS END
// _____________________________ CALENDAR RECORDS 
DataConstructors.calFilters = new Record({
  filtersDisplayed : (localStorage.getItem('acmCalFiltersVisibility') == undefined || localStorage.getItem('acmCalFiltersVisibility') == 'visible') ? true : false,
  showMeetings : null,
  showCalls : null,
  showTasks: null,
  showCompleted: null,
  meetStates: null,
  callStates: null,
  taskStates: null,
 });

DataConstructors.calSetting = new Record({
  showMeetings : true,
  showCalls : true,
  showTasks: true,
  weekNumbers : true,
});

DataConstructors.invitees = new Record({
  Users: List(),
  Contacts: List(),
  Leads: List(),
});

DataConstructors.calendarEvent = new Record({
  id : "",
  name : "",
  status : "",
  date_start : "",
  date_end : "",
  act_type : "",
  all_day: false
});

DataConstructors.calendar = new Record({
  sharedView: null,
  sharedIds : null,
  title: "",
  filters: new DataConstructors.calFilters(),
  setting: new Map(),
  activeTab: "detailMeeting",
  meetingView : new DataConstructors.DetailView(),
  callView : new DataConstructors.DetailView(),
  taskView : new DataConstructors.DetailView(),
  detailView : new DataConstructors.DetailView(),
  invitees  : new Map({relatedInv : new DataConstructors.invitees(), searchInv:new DataConstructors.invitees()}),
  menuOpen : true,
  eventData: null,
  date_start: null,
  date_end: null,
  dashletId : null
});
DataConstructors.actionPanelButton = new Record({
  id: '',
  def: null
});
// _____________________________ CALENDAR RECORDS END
// _____________________________ REPORT WINDOW RECORDS
DataConstructors.reportWindow = new Record({
    main: true,
    modules: List(),
    savedSearch: List(),
    selectedSavedSearchId: null,
    metadata: null,
    module: null,
    fields:List(),
    relate:List(),
    columns: null,
    orderBy: new Map(),
    alterName: new Map(),
    groupBy: new Map(),
    fieldFunction: new Map(),
    vname: null,
    group: null,
    actContent: "filter",
    filterAlert: new Map(),
    activeRelate: {},
    results: null,
    sortedPairs: []
  })
  DataConstructors.reportWindowGroup = new Record({
    type: "group",
    operator: "and",
    children: List(),
  })
  DataConstructors.reportWindowMetadata = new Record({
    id: null,
    name: null,
    description:null,
    cat: null,
    show: null,
    admin_only:null,
    canEdit: null,
    selectedUsers: null,
    selectedRoles: null,
  })
  DataConstructors.selectField = new Record({
    type:"field",
    name: null,
    select: null,
    operator: null,
    value: null,
    parent_type:null,
    def: null,
  })
  DataConstructors.reportRelateField = new Record({
    type: "relate",
    module: null,
    def: null,
    select: null,
    fields: List(),
    relate: List(),
    group: null,
  })
  DataConstructors.reportWindowColumns = new Record({
    active: List(),
    hidden: List(),
  })
  DataConstructors.reportWindowColumn = new Record({
    name: null,
    def: new Map(),
  })
  DataConstructors.reportWindowOrderBy = new Record({
    field:null,
    direction: null,
    module: null,
    priority: null,
    relationship: null,
  })
  DataConstructors.reportWindowGroupBy = new Record({
    field:null,
    active: null,
    module:null,
    priority: null,
    relationship: null,
  })
  DataConstructors.reportWindowFieldFunction = new Record({
    field:null,
    function: null,
    module:null,
  })
  DataConstructors.reportWindowAlterName = new Record({
    field:null,
    vname: null,
    module:null,
  })
  // _____________________________ REPORT WINDOW RECORDS

// _____________________________ HOME RECORDS
DataConstructors.Home = new Record({
  activeId: null,
  tabs: List()
});

DataConstructors.HomeTabs = new Record({
  name: null,
  cols: List()
});

DataConstructors.HomeColumns = new Record({
  rows: List()
});

DataConstructors.HomeColumnsRows = new Record({
  name: null,
  def: new Map({
    type:null,
    show:true
  }),
  data: new Map()
});

// _____________________________ HOME RECORDS END


DataConstructors.EmailSearch = new Record({
  emailUIAction: "",
  mbox: "",
  ieId: "",
  forceRefresh: false,
  getUnread: undefined,
  name: "",
  from_addr: "",
  to_addrs: "",
  searchDateFrom: "",
  searchDateTo: "",
  assigned_user_name: "",
  assigned_user_id: "",
  data_parent_id_search: "",
  data_parent_name_search: "",

  sort: "date",
  dir: "desc",
  start: 0,
  limit: 20,

  id: "",
  simple_search: false,
  body_text: "",

  //dalsi (ne-sugarovske) promenne
  page: 1,
  isImap: "",
  way: ""
});

DataConstructors.Select = new Record({
  label: null,
  value: null
});

DataConstructors.EmailCompose = new Record({
  to_addrs: "",
  cc_addrs: "",
  bcc_addrs: "",
  name: "",
  parent_type: "", //vztahujici se k
  parent_name: "",
  parent_id: "",
  ccExpanded: false,
  bccExpanded: false,
  description_html: "",
  emailTemplate: "", //vybrany template
  emailTemplates: List(),
  signatures: List(), //vsechny dostupne podpisy
  signatureId: null, //vybrany podpis ID
  signature: "", //vybrany podpis obsah
  fromAccounts: List(),
  account: "", //vybrany email ucet
  filledComposeCache: false,
  uid: "", //draft only
  way: "",
  updateEmailText: "",
  attachments: Map(),
  replyMode: "", //[reply|replyAll|forward]
  loaded: false,
  send_individually: false,
  disable_recipient_change: false,
  customData: null,
  recordModule: null,
  recordId: null,
  keepSelf: false,
  header: null,
});

// _____________________________ EMAIL RECORDS
// 99% nazvu je prevzato ze sugaru beze zmeny
// aby v tom nebyl binec
DataConstructors.Emails = new Record({
  folders: List(), // viz DataConstructors.EmailFolders
  foldersIMAP: List(), // viz DataConstructors.EmailFoldersIMAP
  move: new Map({ //drag and drop akce
    action: null,

    // emails
    emailUids: null,
    sourceIeId: null,
    sourceFolder: null,

    // folders
    folderId: null,
    newParentId: null,
  }),
  selectedFolder: new Map({
    mbox: null,
    ieId: null,
    isImap: null,
    way: null
  }),
  selectedEmail: null,
  emails: List(), //viz DataConstructors.EmailList
  activeEmail: new Map(), //viz DataConstructors.EmailDetail
  layout: null,
  view1: null, //aktualni pohled (list, compose, email, reply...)
  view2: null, //aktualni pohled (list, compose, email, reply...)
  advancedSearch: null,
  search: DataConstructors.EmailSearch(), //search data
  compose: DataConstructors.EmailCompose() //search data
});


//emailove sugar slozky v sidebaru
DataConstructors.EmailFolders = new Record({
  text: null,
  id: null,
  ieId: null,
  mbox: null,
  unseen: null,
  folder_type: null,
  expanded: true,
  children: List(),

  // vlastni promenne
  hover: null,
  way: null,
  isImap: false,
});

//emailove imap slozky v sidebaru
DataConstructors.EmailFoldersIMAP = new Record({
  text: null,
  id: null,
  ieId: null,
  mbox: null,
  expanded: true,
  children: List(),
  type: null,
  unseen: null,

  //vlastni promenne
  hover: null,
  way: null,
  isImap: true,
});

//vypis seznamu emailu v dane slozce
DataConstructors.EmailList = new Record({
  flagged: null,
  status: null,
  from: null,
  subject: null,
  description: null,
  date: null,
  uid: null,
  mbox: null,
  ieId: null,
  site_url: null,
  seen: null,
  type: null,
  hasAttach: null,
  to_addrs: null,
  way: null,
  isImap: null // kvuli lastOpenFolder
});

//aktualni rozkliknuty email
DataConstructors.EmailDetail = new Record({
  type: null,
  uid: null,
  ieId: null,
  name: null,
  from_name: null,
  from_addr: null,
  date_start: null,
  time_start: null,
  message_id: null,
  cc_addrs: null,
  attachments: null,
  attachments_array: null,
  toaddrs: null,
  description: null,
  reply_to_addr: null,
  mbox: null,
  cc: null,
  is_sugarEmail: null,
  seen: null, //v sugaru nebylo, pridano
  way: null
});
// _____________________________ EMAIL RECORDS END


// ___________________________________RIGHT PANEL
DataConstructors.RightPanel = new Record({
  show: false,
  customWidth: null,
  content: null,
  reloadOnClose: false,
  closeOnSave: false,
  data: new Map(),
})
// ___________________________________RIGHT PANEL END

// ___________________________________ NOTIFICATION
DataConstructors.notifications = new Record({
  open: false,
  load: true,
  all:false,
  loadMore: false,
  offset: 0,
  notice:0,
  limit: 10,
  list: List(),
})
DataConstructors.notificationsRecord = new Record({
  id: null,
  name: null,
  description: null,
  seen: false,
  notice: false,
  record_name: null,
  parent_module: null,
  parent_record: null,
  notify_date: null,
  type:null,
  url:null,
})
// ___________________________________ NOTIFICATION

// ___________________________________ TIMELINE 
  DataConstructors.timeline = new Record({
    field: null,
    list: null,
    clickable:false,
    onClick: new Map(),
    titles: new Map(),
    colors: new Map(),
  })
// ___________________________________ TIMELINE


// ___________________________________ ADMIN
DataConstructors.admin = new Record({
  sections: List(),
  customData: new Map(),
})
DataConstructors.adminSections = new Record({
  title: null,
  description: null,
  icon:null,
  rows: List()
})
DataConstructors.adminSectionsColumnsRows = new Record({
  title: null,
  description: null,
  icon: null,
  link: null,
})

// ___________________________________ ADMIN END

// ___________________________________ MERGE RECORDS
DataConstructors.mergeRecords = new Record({
  records: List(),
  def: new Map(),
  newRecord: new Map(),
  module: null
})
// ___________________________________ MERGE RECORDS END

// ___________________________________ AKTIVITY TIMELINE
DataConstructors.ActivitiesTimelineView = new Record({
  activities: new Map(),
  status: null,
  module: null,
  id: null,
  records: new List(),
  rows: null,
  inPopup: false,
  order: 'desc',
  format: null,
  limit:20,
  offset:0,
});
// ___________________________________ AKTIVITY TIMELINE END


DataConstructors.AppData = new Record({
    conf: new DataConstructors.conf(),
    view: null,
    menu: new DataConstructors.mainMenu(),
    calendar: new DataConstructors.calendar(),
    reportWindow: new DataConstructors.reportWindow(),
    rightPanel: new DataConstructors.RightPanel(),
    notifications: new DataConstructors.notifications(),
    actionPanel: {
      buttons: []
    }
});




