import detailDefault from "../../detailDefault";

export default class detailAccounts extends detailDefault {
  load(sAction, data) {
    this.updateDetailApiVisibility(sAction, data);

    // ma byt povinne DIC?
      this.setDICRequired(sAction, data);
  }

  update(sAction, data) {
    switch (data.field) {
      case "billing_address_country":
        this.updateDetailApiVisibility(sAction, data)
          this.setDICRequired(sAction, data);
        break;
      case "neplatce_dph":
          this.setDICRequired(sAction, data);
        sAction.dataSet(data.prefix + "/fields/sic_code/def/required", data.value != "1");
        break;
    }
  }

  setDICRequired(sAction, data) {
      sAction.dataSet(data.prefix + "/fields/sic_code/def/required", (
          sAction.dataGet(data.prefix+"/fields/neplatce_dph/value") != "1"
          && sAction.dataGet(data.prefix + "/fields/billing_address_country/value") !== 'POLAND'
      ));
  }

  //fce skryva/odkryva detail fieldy podle zeme, jestli je zeme CZECH REPUBLIC
  updateDetailApiVisibility(sAction, data) {
    let address;
    if (data.value) {
      address = data.value;
    } else {
      address = sAction.dataGet(data.prefix + "/fields/billing_address_country/value");
    }

    const updateFieldApiValue = (fieldName, fieldValue) => {
      if (sAction.dataGet(data.prefix + "/fields/"+fieldName) !== undefined) {
        sAction.dsAdd("set", data.prefix + "/fields/"+fieldName+"/def/showDetailApi", fieldValue);
      }
    }

    sAction.dsClear();
    if (!address || address === "CZECH REPUBLIC") {
      //zobrazi ikony
      updateFieldApiValue("name", "ApiName");
      updateFieldApiValue("account_name", "ApiName");
      updateFieldApiValue("ticker_symbol", "ApiICO");
      updateFieldApiValue("sic_code", "ApiDIC");
      updateFieldApiValue("billing_address_street", "ApiStreet");
      updateFieldApiValue("shipping_address_street", "ApiStreet");
      updateFieldApiValue("billing_address_postalcode", "ApiPSC");
      updateFieldApiValue("shipping_address_postalcode", "ApiPSC");
    }
    else if(address === "SLOVAKIA"){
      //skryje ikony
      updateFieldApiValue("name", "ApiNameSK");
      updateFieldApiValue("account_name", "ApiNameSK");
      updateFieldApiValue("ticker_symbol", "ApiICOSlovakia");
      updateFieldApiValue("sic_code", "");
      updateFieldApiValue("billing_address_street", "ApiStreet");
      updateFieldApiValue("shipping_address_street", "ApiStreet");
      updateFieldApiValue("billing_address_postalcode", "ApiPSC");
      updateFieldApiValue("shipping_address_postalcode", "ApiPSC");
    }
    else if(address === "POLAND"){
      //skryje ikony
      updateFieldApiValue("name", "ApiNameOthers");
      updateFieldApiValue("account_name", "ApiNameOthers");
      updateFieldApiValue("ticker_symbol", "ApiNIPPoland");
      updateFieldApiValue("sic_code", "");
      updateFieldApiValue("billing_address_street", "ApiStreet");
      updateFieldApiValue("shipping_address_street", "ApiStreet");
      updateFieldApiValue("billing_address_postalcode", "");
      updateFieldApiValue("shipping_address_postalcode", "");
    }
    else{
      //skryje ikony
      updateFieldApiValue("name", "ApiNameOthers");
      updateFieldApiValue("account_name", "");
      updateFieldApiValue("ticker_symbol", "");
      updateFieldApiValue("sic_code", "");
      updateFieldApiValue("billing_address_street", "");
      updateFieldApiValue("shipping_address_street", "");
      updateFieldApiValue("billing_address_postalcode", "");
      updateFieldApiValue("shipping_address_postalcode", "");
    }

    //Skryvani/odkryvani tabu Finstat kdyz je nebo neni zeme Slovensko
    const tabs = sAction.dataGet(data.prefix + "/tabs");
    if (tabs) {
      const finstatIndex = tabs.findIndex(i => i.name === "LBL_DETAILVIEW_PANEL_FINSTAT");
      if (finstatIndex !== -1) {
        if (address !== "SLOVAKIA") {
          sAction.dsAdd("set", data.prefix + "/tabs/" + finstatIndex + "/hidden", true);
        } else {
          sAction.dsAdd("set", data.prefix + "/tabs/" + finstatIndex + "/hidden", false);
        }
      }
    }
    sAction.dsProcess();
  }

    beforeSave(sAction, data) {
        if (sAction.dataGet(`${data.prefix}/customData/duplicateICO`) === true) {
            sAction.error(sAction.translate('LBL_NOT_UNIQUE_ICO', 'Accounts'), sAction.translate('LBL_NOT_UNIQUE_ICO_HEADER', 'Accounts'));
            return false;
        }

        // Neoveruje Ares u zahranicnich firem
        if (sAction.dataGet(`${data.prefix}/fields/billing_address_country/value`) !== 'CZECH REPUBLIC') {
            return true;
        }
        // 4.2.3.3 Zkontroluje provolani Aresu a bud ulozi nebo hodi error
        let last_check_ares = sAction.dataGet(data.prefix + '/customData/ares/last_check_ares');
        let today = new Date().toISOString().slice(0, 10);

        if (last_check_ares !== today) {
            sAction.error(sAction.translate('LBL_ARES_NOT_CALLED', 'Accounts'), sAction.translate('LBL_ARES_NOT_CALLED_HEADER', 'Accounts'));
            return false;
        }

        return true;
    }
}
