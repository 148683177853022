export default function loadCustomReport(id, way, data = null) {
  this.customReportXHR(id,data,reponseData => {
    this.dsClear();
    if(data.isDashboard != true){
      this.dsAdd("set",way, {});
    }

    if(way === 'view/dashboard') {
      this.dsAdd('set', way + '/reportId', id);
      this.dsAdd('set', way + '/type', 'customReport');
    }
    this.dsAdd("set",way+"/html", reponseData);
    this.dsAdd("set",way+"/reportId", id);
    this.dsProcess();
  })
}
