export default function importXML(data) {
    var input=document.createElement('input');
    input.type="file";
    input.style="display:none";

    input.onchange = (e) => {
        const sendData = {
            data: {
                module: data.module,
                id: data.id,
                data: data.data ? data.data : [],
                files: e.target.files,
            },
            action: "importFile",
            responseType: "json",
            success: response => {
                this.unLoad();
                if (response.status === false) {
                    this.error(this.translate(response.errorMessage));
                } else if (response.status === true) {
                    if (data && data.callback && data.callback.success) {
                        data.callback.success(response);
                    } else {
                        const data = {
                            // Potvrzení
                            header: this.translate("LBL_CONFIRM"),
                            buttons: [
                                // Zrušit
                                {label: this.translate("LBL_CANCEL", "Home"), callback: () => this.popupHide()},

                                // Pokračovat
                                {label: this.translate("LBL_CONTINUE", "Home"), callback: () => location.reload()},
                            ],
                        };

                        this.popup(this.translate('LBL_DONE'), data);
                    }
                }
            },
            progress: progress => {
            }
        }
        this.load();
        this.filesXhr(sendData);
    }

    setTimeout(function(){
        input.click();
    },200);

}