import React, { Component } from 'react'
import PureComponent from '../../pure'
import TickBox from '../../formElements/TickBox'
import DetailTabContent from "../../detailView/DetailTabContent";
import  sAction  from "sAction";
export default class BoundModuleDetail extends PureComponent {

    handleCheck(prefix, value){
        sAction.dataSet(prefix+"/customData/checked", value);
    }

    handleSelect(prefix, item){
        if(item.id != undefined && item.id != ""){
            sAction.dsClear();
            sAction.dsAdd("set", prefix+"/customData/related_id", item.id);
            sAction.dsAdd("set", prefix+"/customData/related_name", item.name);
            sAction.dsProcess();
        }
    }

    show(e,data){
        e.stopPropagation();
        sAction.rightPanelDetail(data.module,data.record);
    }

    selectCandidate(prefix, item){
        sAction.dsClear();
        sAction.dsAdd("set", prefix+"/customData/checked", false);
        sAction.dsAdd("set", prefix+"/customData/related_id", item.record);
        sAction.dsAdd("set", prefix+"/customData/related_name", item.name);
        sAction.dsProcess();
    }

    handleRadioVal(prefix, e){
        let value = true;
        if(e.target.value != "create"){
            value = false;
        }
        sAction.dataSet(prefix+"/customData/checked", value);
    }

    componentDidMount() {
        const data = this.props.data;

        sAction.custom("load", {
            prefix: this.props.prefix,
            module: data.module
        });
    }

    render(){
        const prefix = this.props.prefix;
        const data = this.props.data;
        const index = this.props.index;

        const tab = data.tabs.get(0);
        const customData = data.get('customData').toJS();
        const module = data.module;

        const checked = customData.checked;

        var customClass = "";
        var reqPom = null;
        let radio = null;
        let headerStr = sAction.translate("LBL_NEW_FORM_TITLE_CL", module);

        let tick = <TickBox
            className="blueTick"
            checked={checked}
            onChange={(value) => this.handleCheck(prefix, value)} />

        var candComp = null;

        var renderComp = null;
        if(checked){
            renderComp = <div className="detailView">
                <DetailTabContent
                    prefix={prefix}
                    module={data.module}
                    index={index}
                    way={prefix + "/tabs/0"}
                    key={data.module}
                    data={tab}
                    rowCount={data.rowCount}
                    id={data.id}
                    fields={data.fields}
                />
            </div> ;
        }


        return (
            <div className={"detailInvComp "+customClass}>
                <div className="detailCard">
                    <div className="header">
                        {tick}
                        <div className="title">{headerStr}</div>
                        {reqPom}
                    </div>
                    {radio}
                    {candComp}
                    {renderComp}
                </div>
            </div>
        );
    }
}
