import React from "react";
import PureComponent from "../../pure";
import  sAction  from "sAction";
import Button from "../../formElements/Button";
import Relate from "../../formElements/Relate";

export default class ImportLeadsPopup extends PureComponent {
    constructor(props) {
        super(props);

        this.input = React.createRef();
        this.state = {
            name: '',
            id: '',
        }
    }

    onCreate(){
        let data = this.props.data.toJS();
        data.data = {};
        data.data.mktListName = this.state.name;
        data.data.mktListID = this.state.id;
        data.callback = {};
        data.callback.success = (response) => {
            const data = {
                // Potvrzení
                header: sAction.translate("LBL_CONFIRM"),
                buttons: [
                    // Zrušit
                    {label: sAction.translate("LBL_CANCEL", "Home"), callback: () => sAction.popupHide()},

                    // Pokračovat
                    {label: sAction.translate("LBL_CONTINUE", "Home"), callback: () => location.reload()},
                ],
            };

            sAction.popup(sAction.translate(response.message.message, 'Leads') + response.message.rows, data);
        };
        sAction.importXML(data);
        sAction.popupHide();
    }

    changeVal(e) {
        this.setState({name: e.name, id: e.id});
    }

    render() {
        return (
            <React.Fragment>
                <div className="reportWindowHeader">
                    <div className="reportWindowHeaderText">{sAction.translate('LBL_IMPORT_LEADS', 'Leads')}</div>
                </div>
                <div className="exportListPopupContent">
                    <div className="simpleForm">
                        <table>
                            <tbody>
                            <tr>
                                <td className="formlabel">{sAction.translate('LBL_MKT_LIST_NAME', 'Leads')}</td>
                                <td>
                                    <Relate
                                        key='relate'
                                        newRecord={true}
                                        data={{
                                            value:  this.state.name,
                                            id_value: this.state.id,
                                        }}
                                        module={'ProspectLists'}
                                        buttons={[]}
                                        callback={e => {this.changeVal(e);}}
                                        inputClassName={"withBorder"}
                                        updateField = {true}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2}>
                                    <div className="viewActionPanelButtons flexRight">
                                        <Button onClick={() => this.props.parent.forceClose()} className='popupCloseButton'>
                                            <div className={"actionPanelButtonIcon icon-closeIconLight"} />
                                            {sAction.translate("LBL_EXPORT_CLOSE")}
                                        </Button>
                                        <Button onClick={() => this.onCreate()}>
                                            <div className={"actionPanelButtonIcon icon-download"} />
                                            {sAction.translate('LBL_IMPORT', 'Leads')}
                                        </Button>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    </div>
            </React.Fragment>
        );
    }
}
