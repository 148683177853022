import React, { Component } from 'react'
import PureComponent from '../../pure'
import SalesPlanLine from './SalesPlanLine'


import  sAction  from "sAction";


class SalesPlanRowsComponent extends PureComponent{
    render(){

        const data = this.props.data;
        const readonly = this.props.readonly;
        const way = this.props.way;

        console.log("way", way);
            
        const linesEstimate = data.lines.estimate;
        const linesAmount = data.lines.amount;
        const header = data.header;
        console.log("data", linesEstimate);
    
        var lineComp = [];

        const ordered = {};
        Object.keys(linesEstimate).sort().forEach(function(key) {
        ordered[key] = linesEstimate[key];
        });

        ordered.forEachObject((line, month) => {
            var amountLine = linesAmount[month];
            

            lineComp.push(<SalesPlanLine 
                dataEst={line}
                dataAmount={amountLine} 
                header={header}
                key={month} 
                readonly={readonly} 
                way={way}
                index={month}
                />
                
            );
        });

        var headerComp = <div className="salesPlanLineContainer">
            <div className="salesPlanEstimateLineContainer">
                <div style={{width:"6%"}}></div>
                <div style={{width:"6%"}}></div>
                <div style={{width:"11%"}}>{sAction.translate("LBL_MEETINGS_NUM", "acm_salesplan_lines")}</div>
                <div style={{width:"11%"}}>{sAction.translate("LBL_MARGIN", "acm_salesplan_lines")}</div>
                <div style={{width:"11%"}}>{sAction.translate("LBL_AMOUNT", "acm_salesplan_lines")}</div>
                <div style={{width:"11%"}}>{header.commodity1}</div>
                <div style={{width:"11%"}}>{header.commodity2}</div>
                <div style={{width:"11%"}}>{header.commodity3}</div>
                <div style={{width:"11%"}}>{header.commodity4}</div>
                <div style={{width:"11%"}}>{header.commodity5}</div>
            </div>
        </div>;
    
        return (
            <div className="detailCard detailViewTabContent detailQuoteProdComponent">
            <div className="salesplanRowsHeader">
                {sAction.translate("LBL_MODULE_NAME", "acm_salesplan_lines")}
            </div>
            {headerComp}
            <div className="salesPlanLinesComContainer">
                {lineComp}
            </div>
            </div>
        )
        }


}
export default SalesPlanRowsComponent