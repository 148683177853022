export default function checkICO(ico, prefix = '') {
  const module = this.dataGet("conf/page");
  this.rest.post("detailapi/checkico", { ico, module }, data => {
    if (data.status === "found") {
      if (data.id !== this.getRecordId()) {
        const msg =
          "<p>" +
          this.translate("LBL_DUPCHECK_MSG_IC_FOUND", "Accounts") +
          "<br /><br />" +
          this.translate("LBL_DUPCHECK_COMPANY_NAME", "Accounts") +
          ": <b>" +
          data.name +
          "</b><br />" +
          this.translate("LBL_DUPCHECK_COMPANY_ADDR", "Accounts") +
          ": <b>" +
          (data.billing_address_street ? data.billing_address_street + ", " : "") +
          (data.billing_address_city ? data.billing_address_city + ", " : "") +
          (data.billing_address_postalcode ? data.billing_address_postalcode : "") +
          "</b> <br /><br />" +
          // Chcete přejít na tento záznam?
          this.translate("LBL_GO_TO_RECORD_CONFIRM", "Accounts") +
          "</p>";

          if (!this.dataGet(prefix + '/customData')) {
              this.dataSet(prefix + '/customData', {});
          }
          this.dataSet(prefix + '/customData/duplicateICO', true);
        this.confrim(msg, () => {
          this.popupHide();
          window.open("#detail/"+module+"/" + data.id);
        });
      } else {
          if (!this.dataGet(prefix + '/customData')) {
              this.dataSet(prefix + '/customData', {});
          }
          this.dataSet(prefix + '/customData/duplicateICO', false);
      }
    } else {
        if (!this.dataGet(prefix + '/customData')) {
            this.dataSet(prefix + '/customData', {});
        }
        this.dataSet(prefix + '/customData/duplicateICO', false);
    }
  });
}
