import React from "react"
import ListViewSearch from "./ListViewSearch";
import ListViewLooks from "./ListViewLooks";
export default function ListViewMenu(props) {
  return (
    <div className="listViewActions">
      <ListViewLooks
        data={props.data.savedSearch}
        hasAssignedUser={props.data.hasAssignedUser}
        hasUUField={props.data.hasUUField}
        module={props.data.modul}
        prefix={props.prefix}
        actSavedSearch={props.data.actSavedSearch}
      />
      <ListViewSearch data={props.data.filter} module={props.data.modul} prefix={props.prefix} />
    </div>
  );
}
