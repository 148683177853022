import React, { Component } from 'react'
import PureComponent from '../../pure'


import  sAction  from "sAction";

import TextField from "@material-ui/core/TextField";
import InputBase from '@material-ui/core/InputBase';


import Relate from "../../formElements/Relate"
import CheckBox from "../../formElements/CheckBox"
import Text from "../../formElements/Text"
import Select from '../../formElements/Select'
import Button from "../../formElements/Button";
import confProd from "./productLineConfig";
import Currency_id from './../../detailViewFields/Currency_id/Currency_id';
import Image from "./Image";


class QProduct extends PureComponent{
    
    constructor(props){
        super(props);
        this.state = {
            descFocused: false
        }
    }
    
    
    valChange(way, val){
        sAction.updateDetailCustom("field", {way:way, value: val});
    }

    uploadImage = (val) => {
        const way = this.props.way + "/" + this.props.ind + "/picture";
        sAction.updateDetailCustom("field", {way: way, value: val});
    }

    productCallback(way, key, item) {
        if (item.id != undefined && item.id != "") {
            //product template has been selected
            sAction.getProductTemplate(item.id, 'prodName', way, key);
        } else {
            sAction.updateDetailCustom("prodName", {way: way + '/' + key, item: item, template: false});
        }
        // Stary kod. Teoreticky nepotrebny. Smazat. Nechavam ho tu pro jistotu kvuli pokazenymu mergi. - Dan
        // if (item.id != undefined && item.id != "") {
        //     //product template has been selected
        //     const searchData = {
        //         fields: ["id"],
        //         getFields: ["id", "name", "cost_price", "list_price", "discount_price", "currency_id", "description", "mft_part_num", "mft_part_num_int"],
        //         value: item.id,
        //         module : "ProductTemplates"
        //     };
        //
        //     sAction.quickSearch(searchData, data => {
        //         if(data.length > 0){
        //             var line = data[0];
        //             //const currency_id = sAction.dataGet(way.split("/productData/", 1)[0]+"/currency_id");//TTT cesta natvrdo
        //             const prefix = way.split("/")[0];
        //
        //             const currency_id = sAction.dataGet(prefix+"/fields/currency_id/value");
        //             // let convRate = sAction.dataGet(prefix+"/fields/kurz/value");
        //
        //             line.cost_price = sAction.currencyConvert(line.cost_price, line.currency_id, currency_id/*, convRate*/);
        //             line.list_price = sAction.currencyConvert(line.list_price, line.currency_id, currency_id/*, convRate*/);
        //             line.discount_amount = sAction.currencyConvert(line.discount_amount, line.currency_id, currency_id/*, convRate*/);
        //             sAction.updateDetailCustom("prodName", {way:way, item: line, template: true});
        //         }
        //     });
        // }
        // else{
        //     sAction.updateDetailCustom("prodName", {way:way, item: item, template: false});
        // }
    }
 
    prodInfo(params){
        window.open("#detail/Products/"+params.id, '_blank')
    }

    blurDescription(way, val){
        //this.setState({descFocused : false});
        if (sAction.dataGet(way) !== val) {
            this.valChange(way, val);
        }
    }

    handleMove(type, way, ind){
        var list = sAction.dataGet(way);
        const cnt = list.size;

        if(type == "up"){
            if(ind == 0){
                return;
            }
            let pomProd = list.get(ind);
            list = list.delete(ind);
            list = list.insert(ind - 1, pomProd);
            
        }
        else if(type == "down"){
            if((ind + 1) >= cnt){
                return;
            }
            let pomProd = list.get(ind + 1);
            list = list.delete(ind + 1);
            list = list.insert(ind, pomProd);
        }

        
        sAction.dsClear();
        sAction.dsAdd("set", way, list);
        sAction.dsAdd("set","view/changes/forceChange", true);
        sAction.dsProcess();
    }

    render(){
 

    const conf = confProd.fields;

    var data = this.props.data;

    console.log("product_template_id", data.name, data.product_template_id);

    var disablePrices = false;

    if(data.product_template_id){
        disablePrices = true;
    }

    const way = this.props.way;
    const ind = this.props.ind;
    const itway = way+"/"+ind;
    const readonly = this.props.readonly;

    const dataProd = {value : data.name, id_value: data.id, searchFields : ["name", "mft_part_num", "mft_part_num_int"]};
    const module = "ProductTemplates";

    const cenotvorba = this.props.cenotvorba;

    var taxClassOptions =[];
    sAction.app_strings.tax_class_dom.forEachObject((lbl, key) => {
        taxClassOptions.push({value: key, label: lbl});
    });

    var curId = this.props.currency_id;
    if(!curId){
        curId = "-99";
    }
    const discountOptions = [{value: "0", label: "ABS"}, {value: "1", label: "%"}];
    const discountVal = data.discount_select;


    let buttons = [];
    if(data.id && !readonly){
        buttons.push({id: "fullForm", className: "icon-expandIcon qProductProdNameIcon",  onClick: this.prodInfo, params: {id : data.id}});
    }

    let descClass = "productLineDescription";
    const keyPre = Date.now();

    var renderComp = [];

    var self = this;

    var relateClass = "qProductProdNameField";
    
    var arrowsContainer = null;
    var discount_price = 0;

    if(readonly){
        relateClass += " readonlyRel";
    }
    else{
        arrowsContainer = <div className="qProductLineButtonContainer">
            <div className={"icon-arrowUp prodLineIcon hoverBlue"} onClick={() => this.handleMove("up", way, ind)}></div>
            <div className={"icon-arrowDown prodLineIcon hoverBlue"} onClick={() => this.handleMove("down", way, ind)}></div>
        </div>
    }

    

    conf.forEach( function(field){ 
        if(!field.visible){
            return;
        }

        switch(field.type){
            case "number":
                renderComp.push(
                    <div style={{width: field.width+"%"}} key={keyPre+"_"+field.name}>
                        <TextField
                            margin="normal"
                            variant="outlined"
                            type="number"
                            defaultValue={data[field.name]}
                            autoComplete="of"
                            disabled={field.disabled || readonly}
                            key={keyPre+"_"+field.name}
                            className="quoteProductField quoteNumberInput"
                            onBlur={(ev) => self.valChange(itway+"/"+field.name, ev.target.value)}
                        />
                    </div>
                );
                break;
            case "relateProd":
                renderComp.push(
                    <div className={relateClass} style={{width: field.width+"%"}} key={keyPre+"_"+field.name}>
                        <Relate
                            newRecord={false}
                            data={dataProd}
                            module={module}
                            buttons={buttons}
                            key={keyPre+"_"+field.name}
                            InputProps={{readOnly: readonly}}
                            callback={(item) => self.productCallback(way, ind, item)}
                        />
                    </div>
                );
                break;
            case "text":
                let customClass = "";
                if(
                    field.name === "mft_part_num_int" &&
                    !data[field.name]                    
                ){
                    customClass = "error";
                }
                renderComp.push(
                    <div style={{width: field.width+"%"}} key={keyPre+"_"+field.name}>
                        <TextField
                            margin="normal"
                            variant="outlined"
                            className={"quoteProductField "+customClass}
                            type="text"
                            autoComplete="of"
                            disabled={field.disabled || readonly}
                            defaultValue={data[field.name]}
                            key={keyPre+"_"+data[field.name]}
                            onBlur={(ev) => self.valChange(itway+"/"+field.name, ev.target.value)}
                        />
                    </div>
                );
                break;
            case "tax_class":
                renderComp.push(
                    <div style={{width: field.width+"%"}} key={keyPre+"_"+field.name}>
                        <Select
                            open={false}
                            name={field.name}
                            options={taxClassOptions}
                            defaultValue={data[field.name]}
                            disabled={field.disabled || readonly}
                            key={keyPre+"_"+field.name}
                            onChange={(ev) => self.valChange(itway+"/"+field.name, ev.target.value)}
                        />
                    </div>
                );
                break;
            case "discount_select":
                renderComp.push(
                    <div style={{width: field.width+"%"}} key={keyPre+"_"+field.name}>
                        <Select
                            open={false}
                            name={field.name}
                            options={discountOptions}
                            defaultValue={discountVal}
                            disabled={field.disabled || readonly}
                            key={keyPre+"_"+field.name}
                            onChange={(ev) => self.valChange(itway+"/"+field.name, +ev.target.value)}
                        />
                    </div>
                );
            break;
            case "price":
                let val = 0;
                let disablePom = field.disabled;
                if(disablePrices){
                    if(
                        field.name === "cost_price" ||
                        field.name === "list_price" ||
                        field.name === "minimum_price"
                    ){
                        disablePom = true;
                    }
                }
                if(field.name == "discount_price"){
                    let discount = sAction.parseNumber(data.discount_amount);
                    if(cenotvorba === "sleva"){
                        if(data.discount_select == 1){
                            discount = (sAction.parseNumber(data.list_price)/100)*discount;
                        }
                        
                        val = (sAction.parseNumber(data.list_price) - discount);
                    }
                    else{
                        //prirazka
                        if(data.discount_select == 1){
                            discount = (sAction.parseNumber(data.cost_price)/100)*discount;
                        }
                        val = (sAction.parseNumber(data.cost_price) + discount);
                    }
                    discount_price = val;
                }
                else if(field.name == "total_price"){
                    let discount = sAction.parseNumber(data.discount_amount);
                    if(cenotvorba === "sleva"){
                        if(data.discount_select == 1){
                            discount = (sAction.parseNumber(data.list_price)/100)*discount;
                        }
                        
                        val = (sAction.parseNumber(data.list_price) - discount)*data.quantity;
                    }
                    else{
                        //prirazka
                        if(data.discount_select  == 1){
                            discount = (sAction.parseNumber(data.cost_price)/100)*discount;
                        }
                        val = (sAction.parseNumber(data.cost_price) + discount)*data.quantity;
                    }
                }
                else{
                    val = sAction.parseNumber(data[field.name]);
                }

                renderComp.push(
                    <div style={{width: field.width+"%"}} key={keyPre+"_"+field.name}>
                    <TextField
                        className="priceInput quoteProductField"
                        margin="normal"
                        variant="outlined"
                        type="text"
                        key={keyPre+"_"+field.name}
                        autoComplete="of"
                        disabled={disablePom || readonly}
                        defaultValue={sAction.formatNumber(val)}
                        onBlur={(ev) => self.valChange(itway+"/"+field.name, sAction.parseNumber(ev.target.value))}
                        onKeyDown={((ev) => {if(ev.keyCode == 13){ev.target.blur();}})}
                    />
                    </div>
                );
                break;
            case "checkbox":
                renderComp.push(
                    <div style={{width: field.width+"%"}} key={keyPre+"_"+field.name}>
                        <CheckBox
                            name={field.name}
                            defaultValue={data[field.name]}
                            disabled={field.disabled || readonly}
                            onChange={(value) => self.valChange(itway+"/"+field.name, +value)}
                        />
                    </div>
                );
                break;
        }

    });

    var descComp = null;
    if(this.state.descFocused){
        descComp = <React.Fragment>
            <Image picture={data.picture} onChange={this.uploadImage} way={itway} />
            <TextField
        className={descClass}
        multiline={true}
        type="text"
        margin="normal"
        variant="outlined"
        rows={10}
        key={keyPre + "_description"}
        onFocus={() => this.setState({descFocused : true})}
        onBlur={(ev) => this.blurDescription(itway+"/description", ev.target.value)}
        defaultValue={data.description}
        autoFocus={true}
    />
        </React.Fragment>
    }
    else{
        descComp = <div>{data.description}</div>
    }
    var prodlinesIcons = <div className="qProductLineButtonContainer">
        <div className="prodLineIcon" ></div>
        <div className="prodLineIcon" ></div>
    </div>;
    if(!readonly){
        prodlinesIcons = <div className="qProductLineButtonContainer">
        <div className="icon-deleteIcon" onClick={() => sAction.updateDetailCustom("delItem", {way : way, index: ind})}></div>
        <div className="icon-editDesc" onClick={() => this.setState({descFocused : !this.state.descFocused})}></div>
        </div>;
    }

    var pomClass = "";
    var pomTitle = "";
    var contLbl = "";

    if(discount_price > data.minimum_price){
        pomClass = "compCompGreen";
        pomTitle = sAction.translate("LBL_PRICE_ABOVE_MIN", "Quotes");
    }
    else if (discount_price > data.cost_price){
        pomClass = "compCompOrange";
        pomTitle = sAction.translate("LBL_PRICE_ABOVE_COST", "Quotes");
    }
    else{
        pomClass = "compCompRed";
        pomTitle = sAction.translate("LBL_PRICE_UNDER_MIN", "Quotes");
    }

    
    if (!data.cost_price || data.cost_price == 0){
        pomClass = "compCompGrey";
        pomTitle = sAction.translate("LBL_PRICE_NO_COST", "Quotes");
    }

    var semComp = <Button variant="text" className={pomClass} title={pomTitle}>
    {contLbl}
    </Button>;
    return (<div>
            <div className="qProductLine">
                <div className="qProductLineButtonContainer">
                    <div
                      onClick={() => sAction.rightPanelDetail("Products", data.id)}
                      className={data.id ? "icon-eye hoverBlue" : ""}
                      style={{margin: "auto", "marginLeft": "2px", "width": "19px", fontSize: 14}}>
                    </div>
                    {data.picture &&
                    <div
                      onClick={() => sAction.popup("image",{url:sAction.param.files+"image/"+data.picture})}
                      className="icon-preview hoverBlue"
                      style={{margin: "auto", "marginLeft": "2px", "width": "19px", fontSize: 13}}>
                    </div>}
                </div>
                {arrowsContainer}
                {prodlinesIcons}
                {renderComp}
                <div className="qqProductButtonsContainer">
                    {semComp}
                </div>
            </div>
            <div className="quoteDescContainer">
                {descComp}
            </div>
        </div>);
    }
}

export default QProduct