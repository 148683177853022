import React, { Component } from 'react'
import PureComponent from '../../pure'
import TextField from "@material-ui/core/TextField";


import  sAction  from "sAction";


class SalesPlanLine extends PureComponent{

    valChange(way, val){
        console.log("valChange", way, val);
        sAction.updateDetailCustom("field", {way:way, value: sAction.parseNumber(val)});
    }

    render(){

        const dataEst = this.props.dataEst;
        const dataAmount = this.props.dataAmount;
        const readonly = this.props.readonly;
        
        const way = this.props.way;
        const index = this.props.index;
        const itway = way+"/lines/estimate/"+index;
        const itwayAmount = way+"/lines/amount/"+index;
        const keyPre = Date.now();
        const header = this.props.header;

        var amount = 0;
        var margin = 0;
        var meetings_num = 0;

        if(dataAmount){
            amount = dataAmount.amount;
            margin = dataAmount.margin;
            meetings_num = dataAmount.meetings_num;
        }


        return (
            <div className="salesPlanLineContainer">
                <div className="salesPlanEstimateLineContainer">
                    <div  style={{width:"6%"}} className="salesPlanMonthCol">
                        {sAction.app_strings.dom_cal_month_long[dataEst.month]}
                    </div>
                    <div  style={{width:"6%"}} className="salesPlanMonthCol">
                        {sAction.translate("LBL_PLAN", "acm_salesplan_lines")}
                    </div>
                    <div  style={{width:"11%"}}>
                        <TextField
                            margin="normal"
                            variant="outlined"
                            type="text"
                            defaultValue={dataEst.meetings_num}
                            autoComplete="of"
                            disabled={readonly}
                            className="quoteProductField quoteNumberInput"
                            onBlur={(ev) => this.valChange(itway+"/meetings_num", ev.target.value)}
                            onKeyDown={((ev) => {if(ev.keyCode == 13){ev.target.blur();}})}
                            onFocus={(ev) => {ev.target.select()}}
                            key={keyPre+"_meetings_num"}
                        />
                    </div>
                    <div  style={{width:"11%"}}>
                        <TextField
                            margin="normal"
                            variant="outlined"
                            type="text"
                            defaultValue={sAction.formatNumber(dataEst.margin)}
                            autoComplete="of"
                            disabled={readonly}
                            className="quoteProductField quoteNumberInput"
                            onBlur={(ev) => this.valChange(itway+"/margin", ev.target.value)}
                            onKeyDown={((ev) => {if(ev.keyCode == 13){ev.target.blur();}})}
                            onFocus={(ev) => {ev.target.select()}}
                            key={keyPre+"_margin"}
                        />
                    </div>
                    <div  style={{width:"11%"}}>
                        <TextField
                            margin="normal"
                            variant="outlined"
                            type="text"
                            defaultValue={sAction.formatNumber(dataEst.amount)}
                            autoComplete="of"
                            disabled={readonly}
                            className="quoteProductField quoteNumberInput"
                            onBlur={(ev) => this.valChange(itway+"/amount", ev.target.value)}
                            onKeyDown={((ev) => {if(ev.keyCode == 13){ev.target.blur();}})}
                            onFocus={(ev) => {ev.target.select()}}
                            key={keyPre+"_amount"}
                        />
                    </div>
                    <div  style={{width:"11%"}}>
                        {header.commodity1 && <TextField
                            margin="normal"
                            variant="outlined"
                            type="text"
                            defaultValue={sAction.formatNumber(dataEst.commodity1)}
                            autoComplete="of"
                            disabled={readonly}
                            className="quoteProductField quoteNumberInput"
                            onBlur={(ev) => this.valChange(itway+"/commodity1", ev.target.value)}
                            onKeyDown={((ev) => {if(ev.keyCode == 13){ev.target.blur();}})}
                            onFocus={(ev) => {ev.target.select()}}
                            key={keyPre+"_commodity1"}
                        />}
                    </div>
                    <div  style={{width:"11%"}}>
                        {header.commodity2 && <TextField
                            margin="normal"
                            variant="outlined"
                            type="text"
                            defaultValue={sAction.formatNumber(dataEst.commodity2)}
                            autoComplete="of"
                            disabled={readonly}
                            className="quoteProductField quoteNumberInput"
                            onBlur={(ev) => this.valChange(itway+"/commodity2", ev.target.value)}
                            onKeyDown={((ev) => {if(ev.keyCode == 13){ev.target.blur();}})}
                            onFocus={(ev) => {ev.target.select()}}
                            key={keyPre+"_commodity2"}
                        />}
                    </div>
                    <div  style={{width:"11%"}}>
                        {header.commodity3 && <TextField
                            margin="normal"
                            variant="outlined"
                            type="text"
                            defaultValue={sAction.formatNumber(dataEst.commodity3)}
                            autoComplete="of"
                            disabled={readonly}
                            className="quoteProductField quoteNumberInput"
                            onBlur={(ev) => this.valChange(itway+"/commodity3", ev.target.value)}
                            onKeyDown={((ev) => {if(ev.keyCode == 13){ev.target.blur();}})}
                            onFocus={(ev) => {ev.target.select()}}
                            key={keyPre+"_commodity3"}
                        />}
                    </div>
                    <div  style={{width:"11%"}}>
                        {header.commodity4 && <TextField
                            margin="normal"
                            variant="outlined"
                            type="text"
                            defaultValue={sAction.formatNumber(dataEst.commodity4)}
                            autoComplete="of"
                            disabled={readonly}
                            className="quoteProductField quoteNumberInput"
                            onBlur={(ev) => this.valChange(itway+"/commodity4", ev.target.value)}
                            onKeyDown={((ev) => {if(ev.keyCode == 13){ev.target.blur();}})}
                            onFocus={(ev) => {ev.target.select()}}
                            key={keyPre+"_commodity4"}
                        />}
                    </div>
                    <div  style={{width:"11%"}}>
                        {header.commodity5 && <TextField
                            margin="normal"
                            variant="outlined"
                            type="text"
                            defaultValue={sAction.formatNumber(dataEst.commodity5)}
                            autoComplete="of"
                            disabled={readonly}
                            className="quoteProductField quoteNumberInput"
                            onBlur={(ev) => this.valChange(itway+"/commodity5", ev.target.value)}
                            onKeyDown={((ev) => {if(ev.keyCode == 13){ev.target.blur();}})}
                            onFocus={(ev) => {ev.target.select()}}
                            key={keyPre+"_commodity5"}
                        />}
                    </div>
                </div>
                {dataAmount && <div className="salesPlanAmountLineContainer">
                    <div  style={{width:"6%"}} className="salesPlanMonthCol">
                    </div>
                    <div  style={{width:"6%"}} className="salesPlanMonthCol">
                    {sAction.translate("LBL_REALITY", "acm_salesplan_lines")}
                    </div>
                    <div  style={{width:"11%"}}>
                        <TextField
                            margin="normal"
                            variant="outlined"
                            type="text"
                            defaultValue={dataAmount.meetings_num}
                            autoComplete="of"
                            disabled={true}
                            className="quoteProductField quoteNumberInput spControlled"
                            onBlur={(ev) => this.valChange(itwayAmount+"/meetings_num", ev.target.value)}
                            onKeyDown={((ev) => {if(ev.keyCode == 13){ev.target.blur();}})}
                            onFocus={(ev) => {ev.target.select()}}
                            key={keyPre+"_meetings_num_amount"}
                        />
                    </div>
                    <div  style={{width:"11%"}}>
                        <TextField
                            margin="normal"
                            variant="outlined"
                            type="text"
                            defaultValue={sAction.formatNumber(dataAmount.margin)}
                            autoComplete="of"
                            disabled={readonly}
                            className="quoteProductField quoteNumberInput"
                            onBlur={(ev) => this.valChange(itwayAmount+"/margin", ev.target.value)}
                            onKeyDown={((ev) => {if(ev.keyCode == 13){ev.target.blur();}})}
                            onFocus={(ev) => {ev.target.select()}}
                            key={keyPre+"_margin_amount"}
                        />
                    </div>
                    <div  style={{width:"11%"}}>
                        <TextField
                            margin="normal"
                            variant="outlined"
                            type="text"
                            defaultValue={sAction.formatNumber(dataAmount.amount)}
                            autoComplete="of"
                            disabled={true}
                            className="quoteProductField quoteNumberInput spControlled"
                            onBlur={(ev) => this.valChange(itwayAmount+"/amount", ev.target.value)}
                            onKeyDown={((ev) => {if(ev.keyCode == 13){ev.target.blur();}})}
                            onFocus={(ev) => {ev.target.select()}}
                            key={keyPre+"_amount_amount"}
                        />
                    </div>
                    <div  style={{width:"11%"}}>
                        {header.commodity1 && <TextField
                            margin="normal"
                            variant="outlined"
                            type="text"
                            defaultValue={sAction.formatNumber(dataAmount.commodity1)}
                            autoComplete="of"
                            disabled={readonly}
                            className="quoteProductField quoteNumberInput"
                            onBlur={(ev) => this.valChange(itwayAmount+"/commodity1", ev.target.value)}
                            onKeyDown={((ev) => {if(ev.keyCode == 13){ev.target.blur();}})}
                            onFocus={(ev) => {ev.target.select()}}
                            key={keyPre+"_commodity1_amount"}
                        />}
                    </div>
                    <div  style={{width:"11%"}}>
                        {header.commodity2 && <TextField
                            margin="normal"
                            variant="outlined"
                            type="text"
                            defaultValue={sAction.formatNumber(dataAmount.commodity2)}
                            autoComplete="of"
                            disabled={readonly}
                            className="quoteProductField quoteNumberInput"
                            onBlur={(ev) => this.valChange(itwayAmount+"/commodity2", ev.target.value)}
                            onKeyDown={((ev) => {if(ev.keyCode == 13){ev.target.blur();}})}
                            onFocus={(ev) => {ev.target.select()}}
                            key={keyPre+"_commodity2_amount"}
                        />}
                    </div>
                    <div  style={{width:"11%"}}>
                        {header.commodity3 && <TextField
                            margin="normal"
                            variant="outlined"
                            type="text"
                            defaultValue={sAction.formatNumber(dataAmount.commodity3)}
                            autoComplete="of"
                            disabled={readonly}
                            className="quoteProductField quoteNumberInput"
                            onBlur={(ev) => this.valChange(itwayAmount+"/commodity3", ev.target.value)}
                            onKeyDown={((ev) => {if(ev.keyCode == 13){ev.target.blur();}})}
                            onFocus={(ev) => {ev.target.select()}}
                            key={keyPre+"_commodity3_amount"}
                        />}
                    </div>
                    <div  style={{width:"11%"}}>
                        {header.commodity4 && <TextField
                            margin="normal"
                            variant="outlined"
                            type="text"
                            defaultValue={sAction.formatNumber(dataAmount.commodity4)}
                            autoComplete="of"
                            disabled={readonly}
                            className="quoteProductField quoteNumberInput"
                            onBlur={(ev) => this.valChange(itwayAmount+"/commodity4", ev.target.value)}
                            onKeyDown={((ev) => {if(ev.keyCode == 13){ev.target.blur();}})}
                            onFocus={(ev) => {ev.target.select()}}
                            key={keyPre+"_commodity4_amount"}
                        />}
                    </div>
                    <div  style={{width:"11%"}}>
                        {header.commodity5 && <TextField
                            margin="normal"
                            variant="outlined"
                            type="text"
                            defaultValue={sAction.formatNumber(dataAmount.commodity5)}
                            autoComplete="of"
                            disabled={readonly}
                            className="quoteProductField quoteNumberInput"
                            onBlur={(ev) => this.valChange(itwayAmount+"/commodity5", ev.target.value)}
                            onKeyDown={((ev) => {if(ev.keyCode == 13){ev.target.blur();}})}
                            onFocus={(ev) => {ev.target.select()}}
                            key={keyPre+"_commodity5_amount"}
                        />}
                    </div>
                </div>}
            </div>
        )
        }


}
export default SalesPlanLine