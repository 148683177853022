import React from "react";

import InputField from "../inputFieldClass";
import  sAction  from "sAction";
import Select from "../../formElements/Select";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";

export default class UU extends InputField {
  constructor() {
    super();
    this.state = {
      state: "fine",
    };
  }

  componentDidMount() {
    const count = this.props.data.def.get("selected").size;
    if (!count) {
      this.addGroup();
    }
  }

  onChange = (e, val, ind) => {
    let i = e.target.value;
    const way = this.props.way; // view/fields/ss

    sAction.dsClear();
    sAction.dsAdd("set", this.props.prefix + "/canSave", true);
    sAction.dsAdd("set", way + "/def/selected/" + ind, val.value);
    sAction.dsProcess();

    this.indicateChange();
  };

  indicateChange = () => {
    let selected = sAction
      .dataGet(this.props.way + "/def/selected")
      .filter((x) => (typeof x === "string" ? true : x.get("id") !== ""))
      .join("|");

    if (!selected) selected = "-1";

    sAction.dsClear();
    sAction.dsAdd("set", this.props.prefix + "/changes/fields/uu", selected);
    sAction.dsAdd("set", this.props.prefix + "/canSave", true);
    sAction.dsAdd("set", this.props.way + "/def/edit", true);
    sAction.dsProcess();
  };

  save = () => {
    sAction.dataSet(this.props.way + "/actEdit", false);
  };

  deleteGroup = (e) => {
    sAction.dataDelete(this.props.way + "/def/selected", e.target.dataset.id);
    this.indicateChange();
  };

  addGroup = () => {
    sAction.dataAdd(this.props.way + "/def/selected", {
      id: "",
      name: "",
    });
  };

  unlockRecord() {
    sAction.dataSet(this.props.prefix + "/canSave", false);
  }

  render() {
    const { data } = this.props;

    const selected = data.def.get("selected");
    const list = data.def.get("list");

    const options = [];
    const optionsMap = {};
    list.forEach((option, i) => {
      let id = option.get("id");
      let name = option.get("name");
      options.push({ title: name, value: id });
      optionsMap[id] = { title: name, value: id };
    });

    let content = [];
    selected.forEach((sel, i) => {
      const key = !sel ? i : sel + "_" + i;

      let defVal = null;
      if(optionsMap[sel]){
        defVal = optionsMap[sel];
      }
      

      content.push(
        <tr key={key}>
          <td className="w100">
            <div className="inputEditContainer" data-fieldname={data.name}>
              <div className="inputContainer uuContainer">
                <Autocomplete
                autoFocus={i==0 ? true : false}
                options={options} 
                defaultValue={defVal} 
                onChange={(ev, val) => this.onChange(ev, val, i)} 
                name={i.toString()} 
                getOptionLabel={opt => opt.title}
                label=""
                size="small"
                renderInput={(params) => <TextField {...params} variant="standard" />}
                disableClearable
                />
              </div>
            </div>
          </td>
          <td>
            <div className="closeButtonContainer">
              <div className="inputEditButton">
                <div
                  tabIndex="0"
                  className={"icon-detailCancel"}
                  onClick={this.deleteGroup}
                  data-id={i}
                  onKeyUp={(e) => {
                    if (e.key === "Enter") {
                      this.deleteGroup(e);
                    }
                  }}
                />
              </div>
            </div>
          </td>
        </tr>
      );
    });

    return (
      <React.Fragment>
        {selected.size > 0 && (
          <table>
            <tbody>{content}</tbody>
          </table>
        )}
        <a
          onKeyDown={e => this.onKeyDown(e, "last",false)}
          onClick={this.addGroup}
          tabIndex="0"
          onKeyUp={(e) => {
            if (e.key === "Enter") {
              this.addGroup();
            }
          }}
        >
          {/* Přidat skupinu */}
          + <span className="icon-assign" />
          {" "+sAction.translate("LBL_ADD_USER", "Studio")}
        </a>
      </React.Fragment>
    );
  }
}
